import { Box, Button } from '@mui/material';
import { FC } from 'react';
import Search from '@/ui/Search';
import { AdminItemConfigurationPageTab } from '@/features/admin/types';
import useGetScreenSize from '@/hooks/useGetScreenSize';

interface AdminItemConfigurationActionBarProps {
  tab: AdminItemConfigurationPageTab;
  searchLabel: string;
  searchValue?: string;
  onSearch: (value: string) => void;
  createText?: string;
  onCreate?: () => void;
  deleteBtnTxt?: string;
  onDelete?: () => void;
  isDeleteDisabled?: boolean;
}

export const AdminItemConfigurationActionBar: FC<AdminItemConfigurationActionBarProps> = ({
  tab,
  searchLabel,
  searchValue = '',
  onSearch,
  createText,
  onCreate,
  isDeleteDisabled,
  deleteBtnTxt,
  onDelete,
}) => {
  const { isSm } = useGetScreenSize('up');

  return (
    <Box paddingX={2} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1}>
      <Search label={searchLabel} name={searchLabel} filterValue={searchValue} onFilterValueChange={onSearch} />
      <Box display="flex" columnGap={1}>
        {onCreate && (
          <Button data-testid={`create-${tab}-btn`} onClick={onCreate} fullWidth={!isSm}>
            {createText}
          </Button>
        )}
        {onDelete && (
          <Button
            disabled={isDeleteDisabled}
            data-testid={`delete-${tab}-btn`}
            color="error"
            onClick={onDelete}
            fullWidth={!isSm}
          >
            {deleteBtnTxt}
          </Button>
        )}
      </Box>
    </Box>
  );
};
