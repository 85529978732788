import { Button, AlertColor, Checkbox, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useSet } from 'react-use';
import { ListLoadingSkeleton } from '@/features/admin/components/LoadingSkeleton';
import Modal from '@/ui/Modal';
import { Result } from '@/ui/Result';
import { AdminItemType } from '@/features/admin/types';
import { useGetAllActions } from '@/features/admin/components/GroupConfigurationHeader/hooks/useGetAllActions';
import { useUpdateGroupActions } from '@/features/admin/components/GroupConfigurationHeader/hooks/useUpdateGroupActions';

interface GroupManageActionsModalProps {
  itemType: AdminItemType;
  itemId: number;
  groupId: string;
  onClose: VoidFunction;
  open: boolean;
  productId: number;
  actionIds?: string[];
}

const getErrorMessage = (
  isErrorGetActions?: boolean,
  isErrorPutActions?: boolean
): { severity: AlertColor; content: string } | undefined => {
  if (isErrorGetActions) {
    return {
      severity: 'error',
      content: 'There was an error getting the actions for this group. Please try again.',
    };
  }

  if (isErrorPutActions) {
    return {
      severity: 'error',
      content: 'There was an error updating the actions for this group. Please try again.',
    };
  }

  return undefined;
};

export const GroupManageActionsModal: FC<GroupManageActionsModalProps> = ({
  itemType,
  open,
  onClose,
  groupId,
  itemId,
  productId,
  actionIds,
}) => {
  const [selectedIds, { has, toggle }] = useSet(new Set(actionIds));

  const { actions, isLoading: isLoadingGetActions, isError: isErrorGetActions } = useGetAllActions(itemType, productId);

  const visibleActions = actions?.filter((action) => !action.hidden);

  const { update, isLoading, isError } = useUpdateGroupActions(itemType, itemId, groupId, actionIds, actions);

  const hasNoActions = !isLoadingGetActions && !visibleActions?.length;

  const isDirty = useMemo(() => {
    const newActionIds = [...selectedIds.values()];
    return !(actionIds?.length === newActionIds.length && actionIds?.every((id) => newActionIds.includes(id)));
  }, [actionIds, selectedIds]);

  const onSave = useCallback(async () => {
    if (!actions) return;

    if (isDirty) {
      await update([...selectedIds.values()]);
    }
    onClose();
  }, [actions, isDirty, onClose, update, selectedIds]);

  return (
    <Modal
      closable={!isLoading}
      message={getErrorMessage(isErrorGetActions, isError)}
      open={open}
      onCancel={onClose}
      title="Manage Group Actions"
    >
      <Modal.Content>
        {hasNoActions ? (
          <Result
            severity="info"
            title="No actions."
            subtitle="There are no available actions to manage for this group."
          />
        ) : (
          <List role="list" sx={{ overflow: 'auto', height: 300 }}>
            {isLoadingGetActions && <ListLoadingSkeleton />}
            {visibleActions?.map((item) => {
              return (
                <ListItemButton
                  divider
                  role="listitem"
                  key={item.actionId}
                  disabled={item.disabled || item.nonEditable}
                  onClick={() => toggle(item.actionId)}
                >
                  <ListItemIcon>
                    <Checkbox
                      inputProps={{ 'aria-label': `Toggle ${item.name} action` }}
                      disabled={item.disabled || item.nonEditable}
                      disableRipple
                      checked={has(item.actionId)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    slotProps={{
                      primary: { sx: { overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'none' } },
                    }}
                  >
                    {item.name}
                  </ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={isLoading} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!visibleActions?.length || !isDirty}
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
