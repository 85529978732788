import { FC, useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Action } from '@mottmac-moata/identity-sdk';
import { Bin as BinIcon, Edit as EditIcon, Tick as TickIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import IconButton from '@mui/material/IconButton';
import { HeaderCell } from '@/features/admin/components/HeaderCell';
import { HeaderSortLabel } from '@/features/admin/components/HeaderSortLabel';
import { FetchErrorResult, NoSearchResult, Result } from '@/ui/Result';
import { TableLoadingSkeleton } from '@/features/admin/components/LoadingSkeleton';
import { useSortByProperties } from '@/hooks/useSortByProperties';
import { openConfirmDialog } from '@/ui/Dialog';
import { setMessage } from '@/store/messages';
import { useAppDispatch } from '@/store/useAppDispatch';
import { AdminItemType } from '@/features/admin/types';
import { AdminItemConfigurationPageTabs } from '@/features/admin/constants';
import AdminItemConfigurationActionBar from '@/features/admin/components/AdminItemConfigurationActionBar';
import { isActionNameReserved } from '@/features/admin/helpers';
import { useDeleteAction } from '@/features/admin/components/GroupConfigurationHeader/hooks/useDeleteAction';
import { useGetAllActions } from '@/features/admin/components/GroupConfigurationHeader/hooks/useGetAllActions';
import { useUserProducts } from '@/hooks/useUserProducts';
import ActionEditModal from '../ActionEditModal';

interface ActionsProps {
  itemType: AdminItemType;
  productId: number;
}

const Actions: FC<ActionsProps> = ({ productId, itemType }) => {
  const dispatch = useAppDispatch();

  const [editAction, setEditAction] = useState<Action>();
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { adminProducts, isLoading: isProductsLoading, error: productsError } = useUserProducts();

  const isProductAdmin = useMemo(
    () => !!adminProducts?.find((adminProduct) => adminProduct?.productId === productId),
    [adminProducts, productId]
  );

  const { actions, isLoading: isActionsLoading, isError: isActionsError } = useGetAllActions(itemType, productId);
  const { deleteAction } = useDeleteAction(itemType);

  const filteredActions = useMemo(
    () =>
      searchValue
        ? actions?.filter((action) => action?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        : actions,
    [searchValue, actions]
  );

  const {
    sorted: filteredAndSortedActions,
    sort: sortActions,
    getIsActive,
    getDirection,
  } = useSortByProperties<Action>(filteredActions);

  const onDelete = useCallback(
    (action: Action): void => {
      openConfirmDialog({
        title: 'Are you sure?',
        content: `This will permanently remove action "${action.name}" from the ${itemType}`,
        onOk: async ({ update, preventAutoClose }) => {
          try {
            await deleteAction({ productId, actionId: action.actionId });
            dispatch(
              setMessage({
                severity: 'success',
                content: `Action "${action.name}" has been deleted`,
              })
            );
          } catch (e) {
            preventAutoClose();
            update({ message: { severity: 'error', content: 'Something went wrong with deleting the action' } });
          }
        },
      });
    },
    [deleteAction, dispatch, itemType, productId]
  );

  const onEditModalOpen = (action?: Action): void => {
    setEditAction(action);
    setIsCreationModalOpen(true);
  };

  const onEditModalClose = (): void => {
    setEditAction(undefined);
    setIsCreationModalOpen(false);
  };

  const isActionDeletable = (action: Action): boolean =>
    // Action READ is created when product is created. Although it is default, but can be removed as it's not a part of 'parent' product
    (!action.default || action.name === 'READ') && !isActionNameReserved(itemType, action.name) && isProductAdmin;

  const isActionEditable = (action: Action): boolean => !action.nonEditable && isActionDeletable(action);

  const isErrorState = (!isActionsLoading && isActionsError) || (!isProductsLoading && productsError);
  const isNoDataState = !isActionsLoading && !isActionsError && !actions?.length;
  const isNoSearchResultState = !isErrorState && !isNoDataState && !!searchValue && !filteredActions?.length;
  const isAbnormalState = isNoSearchResultState || isErrorState || isNoDataState;

  return (
    <>
      <Box data-testid={`${itemType}-actions`} gap={4} display="flex" flexDirection="column">
        <AdminItemConfigurationActionBar
          tab={AdminItemConfigurationPageTabs.Actions}
          searchLabel="Search actions"
          searchValue={searchValue}
          onSearch={setSearchValue}
          createText="Create Action"
          onCreate={isProductAdmin ? () => onEditModalOpen() : undefined}
        />
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label={`table of ${itemType} actions`}>
            <TableHead>
              <TableRow>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['name'])}
                    direction={getDirection(['name'])}
                    onClick={() => sortActions(['name'])}
                  >
                    Actions{filteredAndSortedActions ? ` (${filteredAndSortedActions.length})` : ''}
                  </HeaderSortLabel>
                </HeaderCell>
                {itemType === AdminItemType.Project && (
                  <>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['default'])}
                        direction={getDirection(['default'])}
                        onClick={() => sortActions(['default'])}
                      >
                        DEFAULT
                      </HeaderSortLabel>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['adminGroup'])}
                        direction={getDirection(['adminGroup'])}
                        onClick={() => sortActions(['adminGroup'])}
                      >
                        ADMIN GROUP
                      </HeaderSortLabel>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['hidden'])}
                        direction={getDirection(['hidden'])}
                        onClick={() => sortActions(['hidden'])}
                      >
                        HIDDEN
                      </HeaderSortLabel>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['nonEditable'])}
                        direction={getDirection(['nonEditable'])}
                        onClick={() => sortActions(['nonEditable'])}
                      >
                        NON EDITABLE
                      </HeaderSortLabel>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['disabled'])}
                        direction={getDirection(['disabled'])}
                        onClick={() => sortActions(['disabled'])}
                      >
                        DISABLED
                      </HeaderSortLabel>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderSortLabel
                        active={getIsActive(['resourceMapping'])}
                        direction={getDirection(['resourceMapping'])}
                        onClick={() => sortActions(['resourceMapping'])}
                      >
                        RESOURCE MAPPING
                      </HeaderSortLabel>
                    </HeaderCell>
                  </>
                )}
                {isProductAdmin && (
                  <>
                    <HeaderCell align="center" sx={{ width: '5%' }}>
                      EDIT
                    </HeaderCell>
                    <HeaderCell align="center" sx={{ width: '5%' }}>
                      DELETE
                    </HeaderCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isAbnormalState ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    {isNoDataState && <Result severity="info" title={`There are no actions in this ${itemType}.`} />}
                    {isNoSearchResultState && <NoSearchResult />}
                    {isErrorState && <FetchErrorResult />}
                  </TableCell>
                </TableRow>
              ) : null}

              {isActionsLoading || isProductsLoading ? <TableLoadingSkeleton columns={9} /> : null}

              {filteredAndSortedActions?.map((action) => (
                <TableRow key={action.actionId} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                  <TableCell>{action.name}</TableCell>
                  {itemType === AdminItemType.Project && (
                    <>
                      <TableCell>{action.default && <TickIcon />}</TableCell>
                      <TableCell>{action.adminGroup && <TickIcon />}</TableCell>
                      <TableCell>{action.hidden && <TickIcon />}</TableCell>
                      <TableCell>{action.nonEditable && <TickIcon />}</TableCell>
                      <TableCell>{action.disabled && <TickIcon />}</TableCell>
                      <TableCell>{action.resourceMapping && <TickIcon />}</TableCell>
                    </>
                  )}
                  {isProductAdmin && (
                    <>
                      <TableCell>
                        {isActionEditable(action) && (
                          <IconButton
                            sx={{ marginLeft: 'auto' }}
                            size="small"
                            aria-label="edit"
                            disabled={!isProductAdmin}
                            onClick={() => onEditModalOpen(action)}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {isActionDeletable(action) && (
                          <IconButton
                            sx={{ marginLeft: 'auto' }}
                            size="small"
                            aria-label="delete"
                            onClick={() => onDelete(action)}
                          >
                            <BinIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isCreationModalOpen ? (
        <ActionEditModal
          itemType={itemType}
          productId={productId}
          open={isCreationModalOpen}
          onClose={onEditModalClose}
          initialAction={editAction}
        />
      ) : null}
    </>
  );
};

export default Actions;
