import { Dispatch } from 'redux';
import { UnknownAction, SerializedError } from '@reduxjs/toolkit';

import { resetAuth } from '@/store/auth';
import { setMessage } from '@/store/messages';

/**
 * It takes a serialized error and posts it on the message store
 */
const parseAPIError = (error: SerializedError, dispatch: Dispatch<UnknownAction>): void => {
  // 409 Conflict should be handled by the caller. This occurs for example when creating a project which
  // has a non-unique name (but the user may not have access to the project in order to compare).
  if (error.code === '409') return;
  // Handle fetch errors relating to network issues (most likely)
  if (error.code === 'TypeError' && error.message === 'Failed to fetch') {
    // fetch errors that can occur for a number of reasons - usually because of a network issue
    dispatch(
      setMessage({
        severity: 'error',
        title: 'Request failed',
        content:
          'Check your connection and refresh the page and/or try again. If the problem persists contact your supervisor.',
      })
    );
  }

  if (error.name === 'RequestError' && error.code !== '400') {
    // authentication / authorization error
    if (error.code === '401') {
      // we cannot have provided a valid authentication token
      dispatch(resetAuth());
      dispatch(
        setMessage({
          severity: 'info',
          title: 'Not authenticated',
          content: 'Please log in again.',
        })
      );
    } else if (error.code === '403') {
      dispatch(
        setMessage({
          severity: 'error',
          title: 'Authorization error',
          content: 'You are not authorized to view this resource',
        })
      );
    } else {
      // an api request returned an error, but it was not a bad request error
      // bad request errors should be handled by each async-thunk
      // for other errors we can produce standardised responses
      dispatch(
        setMessage({
          severity: 'error',
          title: 'Request failed',
          content:
            'Check your connection and refresh the page and/or try again. If the problem persists contact your supervisor.',
        })
      );
    }
  }

  // handle authentication errors produced by the auth module
  if (error.name === 'AuthenticationError') {
    dispatch(resetAuth());
    dispatch(
      setMessage({
        severity: 'info',
        title: 'Not authenticated',
        content: 'Please log in again.',
      })
    );
  }
};

export default parseAPIError;
