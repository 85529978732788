import { AppFullRoutePath, AppPage } from '@/router';

type Page = {
  linkName: string;
  linkUrl: string;
  index: number;
  navItemTarget: 'home' | 'marketplace' | 'products';
};

const pages: Page[] = [
  {
    linkName: 'Home',
    linkUrl: AppFullRoutePath[AppPage.Home],
    index: 0,
    navItemTarget: 'home',
  },
  {
    linkName: 'Marketplace',
    linkUrl: AppFullRoutePath[AppPage.MarketPlace],
    index: 1,
    navItemTarget: 'marketplace',
  },
  {
    linkName: 'Products',
    linkUrl: AppFullRoutePath[AppPage.AdminProducts],
    index: 2,
    navItemTarget: 'products',
  },
];

export default pages;
