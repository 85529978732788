import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import Loader from '@/ui/Loader';
import { useAppDispatch } from '@/store/useAppDispatch';
import { logout } from '@/store/auth';
import { setMessage } from '@/store/messages';
import useAsyncThunkDispatch from '@/hooks/useAsyncThunkDispatch';
import useGetScreenSize from '@/hooks/useGetScreenSize';
import { AppFullRoutePath, AppPage } from '@/router';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const Logout: FC = () => {
  useDocumentTitle('Logging out...');

  const { isXs } = useGetScreenSize('only');
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dispatch = useAppDispatch();

  // abort the logout request when we unmount
  const { dispatch: dispatchLogout, error } = useAsyncThunkDispatch(logout);

  useEffect(() => {
    if (error && error.name !== 'AbortError') {
      dispatch(
        setMessage({
          severity: 'error',
          title: 'Your session was not terminated correctly',
          content: 'Check your connection and try again. If the problem persists contact your supervisor.',
        })
      );
      navigate(AppFullRoutePath[AppPage.Login], { replace: true });
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    dispatchLogout({ hash });
  }, [dispatchLogout, hash]);

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{
        p: isXs ? 2 : 4,
      }}
    >
      <Loader sx={{ height: '100%', width: '100%' }} />
    </Stack>
  );
};

export default Logout;
