import { FC } from 'react';
import { AppBar, Toolbar, Stack } from '@mui/material';
import useGetScreenSize from '@/hooks/useGetScreenSize';
import { HEADER_HEIGHT } from './constants';
import HelperMenu from './HelperMenu';
import UserMenu from './UserMenu';
import StdHeaderLinks from './HeaderLinks/StdHeaderLinks';
import SmHeaderLinks from './HeaderLinks/SmHeaderLinks';
import SiteLogo from './SiteLogo';

const Header: FC = () => {
  const { isMd } = useGetScreenSize('up');

  return (
    <>
      <AppBar sx={{ isolation: 'isolate' }}>
        <Toolbar
          disableGutters
          variant="dense"
          sx={{
            position: 'static',
            height: HEADER_HEIGHT,
            minHeight: HEADER_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexGrow: 1,
              mr: 'auto',
              width: 'calc(75% - 1rem)',
              maxWidth: 'calc(75% - 1rem)',
            }}
          >
            <SiteLogo />
            {isMd && (
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexGrow: 1,
                  overflow: 'hidden',
                }}
              >
                <StdHeaderLinks />
              </Stack>
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1,
              px: 2,
              ml: 'auto',
              width: '25%',
              minWidth: '25%',
            }}
          >
            <HelperMenu />
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      {!isMd && <SmHeaderLinks />}
    </>
  );
};

export default Header;
