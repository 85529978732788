import { Breadcrumbs, Stack } from '@mui/material';
import { FC, useState } from 'react';
import Loader from '@/ui/Loader';
import Search from '@/ui/Search';
import { FetchErrorResult } from '@/ui/Result';
import { GroupUsersTable } from '@/features/admin/components/GroupUsersTable';
import { useCurrentGroupId } from '@/features/admin/hooks/useCurrentGroupId';
import { useCurrentProductId } from '@/features/admin/hooks/useCurrentProductId';
import { useCurrentProduct } from '@/features/admin/hooks/useCurrentProduct';
import { ProductGroupAPI } from '@/services/api/ProductGroupAPI';
import { AdminItemType } from '@/features/admin/types';
import { GroupConfigurationHeader } from '@/features/admin/components/GroupConfigurationHeader';

export const ProductGroupConfigurationPage: FC = () => {
  const groupId = useCurrentGroupId();
  const productId = useCurrentProductId();

  const { data: product, isLoading: isProjectLoading, isError: isProjectError } = useCurrentProduct();
  const {
    data: group,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = ProductGroupAPI.useGetById(Number(productId), String(groupId), { enabled: !!(productId && groupId) });

  const [filterValue, setFilterValue] = useState('');

  if (!productId || !groupId || isGroupError || isProjectError) {
    return <FetchErrorResult />;
  }

  if (isProjectLoading || isGroupLoading) return <Loader flex={1} />;

  // If there is no error and loading is done, we will have a project and group. So this acts as a type guard.
  if (!product || !group) return null;

  return (
    <>
      <Breadcrumbs />
      <GroupConfigurationHeader
        itemType={AdminItemType.Product}
        group={group}
        itemId={Number(productId)}
        productId={product?.productId ?? 0}
      />
      <Stack padding={2}>
        <Search
          label="Search Users"
          name="searchUsers"
          filterValue={filterValue}
          onFilterValueChange={setFilterValue}
        />
      </Stack>
      <GroupUsersTable
        itemType={AdminItemType.Product}
        itemId={Number(productId)}
        groupId={groupId}
        filterValue={filterValue}
      />
    </>
  );
};
