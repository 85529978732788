import { FC } from 'react';
import { Box } from '@mui/material';

import { FetchErrorResult } from '@/ui/Result';
import Loader from '@/ui/Loader';
import assertIsNotUndefined from '@/utils/assertIsNotUndefined';

import { TermsAndConditionsData } from '../types';

interface Props {
  content: TermsAndConditionsData | undefined;
  isLoading: boolean;
  isError: boolean;
}

const TermsAndConditionsContent: FC<Props> = ({ content, isLoading, isError }: Props) => {
  if (isError) return <FetchErrorResult />;

  if (isLoading) return <Loader data-testid="loader-circular-progress" />;

  const termsAndConditionsData = assertIsNotUndefined<TermsAndConditionsData>(content);

  return <Box>{termsAndConditionsData.terms}</Box>;
};

export default TermsAndConditionsContent;
