import useGetScreenSize from '@/hooks/useGetScreenSize';

const getGridColumns = (xl: boolean, lg: boolean, md: boolean): number => {
  if (xl) return 4;
  if (lg) return 3;
  if (md) return 2;

  return 1;
};

/**
 * Returns the number of columns for the recent projects list based on the width of the screen
 */
const useGetGridColumns = (): number => {
  const { isXL, isLg, isMd } = useGetScreenSize();

  return getGridColumns(isXL, isLg, isMd);
};

export default useGetGridColumns;
