import { FC, PropsWithChildren } from 'react';
import { useCurrentProjectId } from '@/features/admin/hooks/useCurrentProjectId';
import { AdminItemType } from '@/features/admin/types';
import { UserAPI } from '@/services/api/UserAPI';
import { GroupAdminRouteGuard } from '../GroupAdminRouteGuard/GroupAdminRouteGuard';

export const ProjectGroupAdminRouteGuard: FC<PropsWithChildren> = ({ children }) => {
  const projectId = useCurrentProjectId();
  const { data, isLoading, isError } = UserAPI.useFetchProjects({
    orderBy: 'recentprojects',
    displayAdminUsers: false,
  });

  const isGroupAdmin = data?.find((project) => project.projectId === projectId)?.isGroupAdmin ?? false;

  return (
    <GroupAdminRouteGuard
      isGroupAdmin={isGroupAdmin}
      isLoading={isLoading}
      isError={isError}
      itemType={AdminItemType.Project}
    >
      {children}
    </GroupAdminRouteGuard>
  );
};
