import TagManager from 'react-gtm-module';
import { GTM_ID } from '@/configs';

export const initializeGTM = (): void => {
  if (!GTM_ID) return;

  TagManager.initialize({
    gtmId: GTM_ID,
  });
};
