import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-use';
import { ItemManagementController, ListItem } from '@/features/admin/components/ItemsListManageModal/types';
import { AdminItemType } from '@/features/admin/types';
import { useGetItemUsers } from '@/features/admin/components/GroupConfigurationHeader/hooks/useGetItemUsers';
import { useUpdateGroupUsers } from '@/features/admin/components/GroupConfigurationHeader/hooks/useUpdateGroupUsers';
import { useGetGroupUsers } from './useGetGroupUsers';

export const useGroupUserController = (
  itemType: AdminItemType,
  itemId: number,
  groupId: string
): ItemManagementController => {
  const [isDirty, setIsDirty] = useState(false);

  const {
    users: groupUsers,
    isLoading: isLoadingGroupMembers,
    isError: isGroupUsersError,
  } = useGetGroupUsers(itemType, itemId, groupId);

  const { users: allUsers, isLoading: isLoadingAllUsers, error: allUsersError } = useGetItemUsers(itemType, itemId);

  const {
    update: updateUsers,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useUpdateGroupUsers(itemType, itemId, groupId);

  const projectUsersItems = useMemo(
    () => allUsers?.map(({ displayName, userId, email }) => ({ name: displayName, id: userId, email })),
    [allUsers]
  );

  const [newGroupUsers, { set, setAll, remove }] = useMap<Record<string, ListItem>>();
  const groupMembers = useMemo(() => Object.values(newGroupUsers), [newGroupUsers]);

  const error =
    isUpdateError || allUsersError || isGroupUsersError
      ? 'There was an error updating the users of this group. Please try again.'
      : undefined;
  const isLoading = isLoadingGroupMembers || isLoadingAllUsers || isUpdateLoading;

  const init = useCallback((): void => {
    if (groupUsers) {
      setAll(
        groupUsers.reduce(
          (acc, user) => ({ ...acc, [user.userId]: { name: user?.displayName, id: user.userId, email: user?.email } }),
          {}
        )
      );
    }
  }, [groupUsers, setAll]);

  useEffect(() => {
    init();
  }, [init]);

  // Prospects are projects users (not pending users) which are not already in the group.
  const groupProspects = useMemo(
    () => projectUsersItems?.filter(({ id, name }) => name && !newGroupUsers[id]) ?? [],
    [newGroupUsers, projectUsersItems]
  );

  const addItems = useCallback(
    (userIds: string[]) => {
      setIsDirty(true);
      userIds.forEach((userId) => {
        const user = groupProspects.find(({ id }) => id === userId);
        if (user) set(userId, user);
      });
    },
    [groupProspects, set]
  );

  const removeItems = useCallback(
    (userIds: string[]) => {
      setIsDirty(true);
      userIds.forEach((id) => remove(id));
    },
    [remove]
  );

  const onUpdate = useCallback(async (): Promise<boolean> => {
    await updateUsers(groupMembers.map(({ id }) => id));
    return true;
  }, [updateUsers, groupMembers]);

  return useMemo(
    () => ({
      isDirty,
      isLoadingMembers: isLoadingGroupMembers,
      isLoading,
      error,
      memberItems: groupMembers,
      itemProspects: groupProspects,
      addItems,
      removeItems,
      onUpdate,
      reset: init,
    }),
    [
      addItems,
      groupMembers,
      groupProspects,
      isDirty,
      error,
      isLoading,
      isLoadingGroupMembers,
      onUpdate,
      removeItems,
      init,
    ]
  );
};
