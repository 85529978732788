import { FC, PropsWithChildren } from 'react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';
import theme from './theme';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider
        theme={theme}
        defaultMode="light"
        // only used to provide the theme context, do not generate CSS .
        disableStyleSheetGeneration
      >
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
