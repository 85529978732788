import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import { AppFullRoutePath, AppPage } from '@/router';
import { UserAPI } from '@/services/api/UserAPI';
import { useRedirectSearchParamsHandler } from './useRedirectSearchParamsHandler';

const useRedirectIfLoggedIn = ({
  disabled = false,
  handleRedirectSearchParamsFromPage,
}: {
  disabled?: boolean;
  handleRedirectSearchParamsFromPage?: AppPage.Login | AppPage.Callback;
} = {}): void => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { data: user } = UserAPI.useFetchMe();
  const { handle: handleRedirect } = useRedirectSearchParamsHandler();

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      if (disabled || !isAuthenticated || !user || abortController.signal.aborted) return;

      // handle redirect params on login page or callback page
      if (handleRedirectSearchParamsFromPage) {
        await handleRedirect({ page: handleRedirectSearchParamsFromPage }, { signal: abortController.signal });
        return;
      }

      // first time users
      if (user.isFirstTimeUser) {
        navigate(AppFullRoutePath[AppPage.UserUpdate], { replace: true });
        return;
      }

      // home page
      navigate(AppFullRoutePath[AppPage.Home], { replace: true });
    })();

    return () => {
      abortController.abort();
    };
  }, [disabled, handleRedirect, handleRedirectSearchParamsFromPage, isAuthenticated, navigate, user]);
};

export default useRedirectIfLoggedIn;
