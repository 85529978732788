import {
  Breadcrumbs,
  Stack,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { FC, useState } from 'react';
import Loader from '@/ui/Loader';
import { FetchErrorResult } from '@/ui/Result';
import Search from '@/ui/Search';
import { useCurrentProject } from '@/features/admin/hooks/useCurrentProject';
import { useCurrentProjectId } from '@/features/admin/hooks/useCurrentProjectId';
import { useCurrentProjectUserId } from '@/features/admin/hooks/useCurrentProjectUserId';
import { useCurrentProjectUser } from '@/features/admin/hooks/useCurrentProjectUser';
import { ProjectUserGroupsTable } from '@/features/admin/components/ProjectUserGroupsTable';
import { ProjectUserConfigurationHeader } from '@/features/admin/components/ProjectUserConfigurationHeader';
import { HeaderCell } from '@/features/admin/components/HeaderCell';

export const ProjectUserConfigurationPage: FC = () => {
  const projectUserId = useCurrentProjectUserId();
  const projectId = useCurrentProjectId();

  const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useCurrentProject();
  const { data: user, isLoading: isUserLoading, isError: isUserError } = useCurrentProjectUser();

  const [filterValue, setFilterValue] = useState('');

  if (!projectId || !projectUserId || isProjectError || isUserError) {
    return <FetchErrorResult />;
  }

  if (isProjectLoading || isUserLoading) return <Loader flex={1} />;

  // If there is no error and loading is done, we will have a project and user. So this acts as a type guard.
  if (!project || !user) return null;

  return (
    <>
      <Breadcrumbs />
      <ProjectUserConfigurationHeader projectId={Number(projectId)} user={user} />
      <Stack padding={2}>
        <Search
          label="Search Groups"
          name="searchGroups"
          filterValue={filterValue}
          onFilterValueChange={setFilterValue}
        />
      </Stack>
      {user?.appMetaData && (
        <TableContainer component={Paper}>
          <Table aria-label="User app meta data">
            <TableHead>
              <TableRow>
                <HeaderCell>M2M Details</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {Object.keys(user?.appMetaData).map((key) => (
                    <Typography>
                      {key}: {String(user?.appMetaData?.[key])}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ProjectUserGroupsTable
        userGroupIds={user?.groups}
        projectId={Number(projectId)}
        userId={projectUserId}
        filterValue={filterValue}
      />
    </>
  );
};
