import { FC } from 'react';
import { ButtonBase } from '@mui/material';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import MoataLogo from '@/assets/images/moata.svg?react';
import { AppFullRoutePath, AppPage } from '@/router';
import { GTMEvents, trackGTMEvent } from '@/services/telemetry/gtm';
import { HEADER_HEIGHT } from '../constants';

const SiteLogo: FC = () => {
  return (
    <ButtonBase
      component={ReactRouterNavLink}
      sx={{
        height: HEADER_HEIGHT,
        // this number can make the "M" of the "MOATA" align with the single "M" on SubApp
        aspectRatio: '2.23/1',
        overflow: 'hidden',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.main',
        flexShrink: 0,
        flexGrow: 0,
      }}
      title="Home"
      to={AppFullRoutePath[AppPage.Home]}
      data-gtm-header-logo
      onClick={() => {
        trackGTMEvent(GTMEvents.header.logoClick());
      }}
    >
      <MoataLogo height="1rem" role="img" aria-label="Moata Logo" />
    </ButtonBase>
  );
};

export default SiteLogo;
