import { useMemo } from 'react';
import { useAccessTokenPayload } from '@/hooks/useAccessTokenPayload';

const FALLBACK_NAME = 'User';

export const useUserInfo = (): {
  displayName: string;
  email: string;
} => {
  const payload = useAccessTokenPayload();

  const result = useMemo(() => {
    return {
      displayName: payload?.name || FALLBACK_NAME,
      email: payload?.emails?.[0] || '',
    };
  }, [payload?.emails, payload?.name]);

  return result;
};
