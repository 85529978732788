import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import { Link as RouterLink } from 'react-router-dom';
import { AppFullRoutePath, AppPage } from '@/router';
import useAuth from '@/hooks/useAuth';
import { GTMEvents, trackGTMEvent } from '@/services/telemetry/gtm';
import { useUserInfo } from '../useUserInfo';

const XsUserMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  const { isAuthenticated } = useAuth();

  const userInfo = useUserInfo();

  return (
    <Dialog
      open={!!anchorElement}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="dialog-title"
      >
        Account
        <IconButton edge="end" size="small" aria-label="info" onClick={handleClose} data-testid="close-helper-icon">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <List component="div" disablePadding>
        {isAuthenticated ? (
          <ListItem component="div">
            <ListItemText
              primary={
                <Stack>
                  <Typography color="text.secondary">{userInfo.displayName}</Typography>
                  <Typography color="text.secondary">{userInfo.email}</Typography>
                </Stack>
              }
            />
          </ListItem>
        ) : null}
        {isAuthenticated ? (
          <ListItemButton
            component={RouterLink}
            to={AppFullRoutePath[AppPage.Logout]}
            onClick={() => {
              trackGTMEvent(GTMEvents.header.accountItemLogoutClick());
              handleClose();
            }}
          >
            <ListItemText primary="Log out" />
          </ListItemButton>
        ) : (
          <ListItemButton component={RouterLink} to={AppFullRoutePath[AppPage.Login]} onClick={handleClose}>
            <ListItemText primary="Log in" />
          </ListItemButton>
        )}
        <Divider />
        <ListItemButton component={RouterLink} to={AppFullRoutePath[AppPage.TermsAndConditions]} onClick={handleClose}>
          <ListItemText primary="Terms & conditions" />
        </ListItemButton>
        <Divider />
        <ListItemButton component={RouterLink} to={AppFullRoutePath[AppPage.TermsAndConditions]} onClick={handleClose}>
          <ListItemText primary="Privacy policy" />
        </ListItemButton>
        <Divider />
        <ListItem component="div">
          <ListItemText primary={<>&copy; {new Date().getFullYear()} Mott MacDonald</>} />
        </ListItem>
      </List>
    </Dialog>
  );
};

export default XsUserMenu;
