import { FC } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';

import { FetchErrorResult } from '@/ui/Result';
import Loader from '@/ui/Loader';
import { UserAPI } from '@/services/api/UserAPI';
import TabsContent from './TabsContent';

interface Props {
  projectId: number;
}

const ProjectsPanelContent: FC<Props> = ({ projectId }: Props) => {
  const {
    data: userProjectDto,
    isError: isUserProjectDtoError,
    isLoading: isProjectDtoLoading,
  } = UserAPI.useGetProjectById(projectId);

  if (isUserProjectDtoError) {
    return <FetchErrorResult />;
  }

  if (userProjectDto === undefined || isProjectDtoLoading) {
    return (
      <Stack spacing={6} paddingY={6}>
        <Loader data-testid="detail-panel-loader" />
      </Stack>
    );
  }

  const { name, productShortName, admins, isGroupAdmin, projectSummary } = userProjectDto;

  return (
    <>
      <Stack p={2} direction="row" alignItems="center" justifyContent="flex-start">
        {productShortName && (
          <Avatar variant="square" sx={{ bgcolor: 'text.primary', marginRight: 1 }} aria-label={name}>
            <Typography variant="h6">{productShortName}</Typography>
          </Avatar>
        )}

        <Typography variant="subtitle1" noWrap>
          {name}
        </Typography>
      </Stack>

      <TabsContent
        projectSummary={projectSummary}
        admins={admins}
        projectId={projectId}
        isCurrentUserAdmin={isGroupAdmin}
      />
    </>
  );
};

export default ProjectsPanelContent;
