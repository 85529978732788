import { CreateProductDto, GetProductsQueryParams, ProductMetadata } from '@mottmac-moata/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useQuery, UseMutationResult, useMutation, UseQueryResult } from 'react-query';
import useAuth from '@/hooks/useAuth';
import { authorisedIdentityApiClient } from '@/services/api/identityApiClient';
import { QUERY_KEY_VARIANTS } from '@/services/api/constants';
import { PRODUCT_BASE_QUERY_KEY } from './constants';

export const ProductAPI = {
  queryKeys: {
    base: PRODUCT_BASE_QUERY_KEY,
    list: () => [ProductAPI.queryKeys.base, QUERY_KEY_VARIANTS.LIST] as QueryKey,
    detail: (productId: number) => [ProductAPI.queryKeys.base, QUERY_KEY_VARIANTS.DETAIL, productId] as QueryKey,
  },

  useFetchProducts: (
    queryParams?: GetProductsQueryParams,
    queryOptions?: UseQueryOptions<ProductMetadata[], SerializedError>
  ): UseQueryResult<ProductMetadata[], SerializedError> => {
    const { getAccessToken } = useAuth();
    return useQuery(
      ProductAPI.queryKeys.list(),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getAccessToken()).Product.getProducts(queryParams, { signal }),
      queryOptions
    );
  },

  useCreateProduct: (): UseMutationResult<ProductMetadata, SerializedError, CreateProductDto> => {
    const { getAccessToken } = useAuth();

    return useMutation({
      mutationFn: async (data: CreateProductDto) => {
        const token = await getAccessToken();
        return authorisedIdentityApiClient(token).Product.create(data);
      },
    });
  },

  useGetById: (productId: number, options?: UseQueryOptions<ProductMetadata, SerializedError>) => {
    const { getAccessToken } = useAuth();

    return useQuery(
      ProductAPI.queryKeys.detail(productId),
      async ({ signal }) => authorisedIdentityApiClient(await getAccessToken()).Product.getById(productId, { signal }),
      options
    );
  },
};
