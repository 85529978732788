import { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

import Header from '@/features/lobby/components/Header';

const GlobalLobbyLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <Stack sx={{ flex: 1 }}>{children}</Stack>
    </>
  );
};

export default GlobalLobbyLayout;
