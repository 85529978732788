import { FC } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useRedirectIfLoggedIn from '@/features/auth/hooks/useRedirectIfLoggedIn';
import { clearIdpLogoutUrl } from '@/store/auth';
import useGetScreenSize from '@/hooks/useGetScreenSize';
import { AppFullRoutePath, AppPage } from '@/router';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useAppDispatch } from '@/store/useAppDispatch';
import { useAppSelector } from '@/store/useAppSelector';

const urlIeCheck = (url: string): string => (url.includes('__') ? url.replace('__', '') : url);

const LogoutConfirmation: FC = () => {
  useDocumentTitle('Logged out');

  const { isXs } = useGetScreenSize('only');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const idpLogoutUrl = useAppSelector((state) => state.auth.idpLogoutUrl);

  const handleHomeRedirect = (): void => {
    dispatch(clearIdpLogoutUrl());
    navigate(AppFullRoutePath[AppPage.Login]);
  };

  const handleIdpLogout = (): void => {
    dispatch(clearIdpLogoutUrl());
    if (idpLogoutUrl) {
      window.location.replace(urlIeCheck(idpLogoutUrl));
    }
  };

  useRedirectIfLoggedIn();

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{
        p: isXs ? 2 : 4,
      }}
    >
      {idpLogoutUrl ? (
        <>
          <Typography>You have been logged out from Moata.</Typography>
          <Typography>
            If you want to logout from your company&apos;s Identity Provider as well please click on the &quot;Log
            out&quot; button below.
          </Typography>
          <Stack direction={isXs ? 'column' : 'row'} spacing={2} justifyContent="flex-end" sx={{ pt: isXs ? 0 : 2 }}>
            <Button variant="outlined" onClick={handleHomeRedirect}>
              Return to login
            </Button>
            <Button onClick={handleIdpLogout}>Log out</Button>
          </Stack>
        </>
      ) : (
        <>
          <Typography>
            You have been logged out from Moata. Please click the button below to return to the login page.
          </Typography>
          <Stack direction={isXs ? 'column' : 'row'} justifyContent="flex-end" sx={{ pt: isXs ? 0 : 2 }}>
            <Button onClick={handleHomeRedirect}>Return to login</Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default LogoutConfirmation;
