import { FC, PropsWithChildren } from 'react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import '@mott-macdonald/smi-react-ui-kit/fonts.css';
import theme from './theme';

const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme} defaultMode="light">
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
