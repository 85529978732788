import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Checkbox, Chip, Link, Tooltip } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { ProductGroupDTO, ProjectGroupBaseDTO } from '@mottmac-moata/identity-sdk';
import { FC, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { FetchErrorResult, NoSearchResult, Result } from '@/ui/Result';
import { HeaderCell } from '@/features/admin/components/HeaderCell';
import { RouteBuilder } from '@/router';
import { HeaderSortLabel } from '@/features/admin/components/HeaderSortLabel';
import { useSortByProperties } from '@/hooks/useSortByProperties';
import { useGroupsById } from '@/features/admin/hooks/useGroupsById';
import { AdminItemType } from '@/features/admin/types';
import { AdminItemConfigurationPageTabLabels, AdminItemConfigurationPageTabs } from '@/features/admin/constants';
import AdminItemConfigurationActionBar from '@/features/admin/components/AdminItemConfigurationActionBar';
import GroupCreateModal from '@/features/admin/components/GroupCreateModal';
import { useGroupDelete } from '@/features/admin/hooks/useGroupDelete';
import { isNonNullable } from '@/utils/filters';
import type { StringAlias } from '@/types/StringAlias';
import { TableLoadingSkeleton } from '../LoadingSkeleton';

interface GroupsProps {
  itemType: AdminItemType;
  itemId: number;
  adminGroupId?: StringAlias.UUID;
}

export const Groups: FC<GroupsProps> = ({ itemType, itemId, adminGroupId }) => {
  const { groups, isLoading, error } = useGroupsById(itemType, itemId);
  const { selectedGroupIds, selectGroup, openDeleteConfirmation } = useGroupDelete(itemType, Number(itemId));

  const [searchValue, setSearchValue] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const filteredGroups = useMemo(
    () => groups?.filter((group) => group?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())),
    [searchValue, groups]
  );

  const {
    sorted: filteredAndSortedGroups,
    sort: sortGroups,
    getIsActive,
    getDirection,
  } = useSortByProperties<ProjectGroupBaseDTO | ProductGroupDTO>(filteredGroups);

  const isErrorState = !isLoading && error && !groups;
  const isNoDataState = !isLoading && !error && !groups?.length;
  const isNoSearchResultState = !isErrorState && !isNoDataState && !!searchValue && !filteredAndSortedGroups?.length;
  const isAbnormalState = isErrorState || isNoSearchResultState || isNoDataState;

  return (
    <>
      <Box data-testid={`${itemType}-groups`} gap={4} display="flex" flexDirection="column">
        <AdminItemConfigurationActionBar
          tab={AdminItemConfigurationPageTabs.Groups}
          searchLabel={AdminItemConfigurationPageTabLabels[AdminItemConfigurationPageTabs.Groups].searchLabel}
          searchValue={searchValue}
          onSearch={setSearchValue}
          createText="Create Group"
          onCreate={() => setIsCreateModalOpen(true)}
          deleteBtnTxt={`Delete ${selectedGroupIds.length > 0 ? selectedGroupIds.length : ''} ${pluralize('Group', selectedGroupIds.length)}`}
          isDeleteDisabled={selectedGroupIds.length === 0}
          onDelete={openDeleteConfirmation}
        />
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label={`table of ${itemType} user groups`}>
            <TableHead>
              <TableRow>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['name'])}
                    direction={getDirection(['name'])}
                    onClick={() => sortGroups(['name'])}
                  >
                    GROUPS{filteredAndSortedGroups ? ` (${filteredAndSortedGroups.length})` : ''}
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell />
              </TableRow>
            </TableHead>
            <TableBody aria-label="Notification group table content">
              {isAbnormalState && (
                <TableRow>
                  <TableCell colSpan={2}>
                    {isNoDataState && (
                      <Result severity="info" title={`There are no groups configured to this ${itemType}.`} />
                    )}
                    {isNoSearchResultState && <NoSearchResult />}
                    {isErrorState && <FetchErrorResult />}
                  </TableCell>
                </TableRow>
              )}

              {isLoading && <TableLoadingSkeleton columns={1} firstColumnColSpan={2} />}

              {filteredAndSortedGroups?.map((group) => (
                <TableRow key={group.groupId} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                  <TableCell>
                    <Box display="flex" alignItems="center" columnGap={1}>
                      <Avatar>{group.name?.substring(0, 1).toUpperCase()}</Avatar>
                      <Link
                        to={`/${RouteBuilder.adminGroup(itemType, itemId, group.groupId)}`}
                        component={RouterLink}
                        underline="always"
                        sx={{ color: 'text.primary' }}
                      >
                        {group.name}
                      </Link>
                      {isNonNullable(adminGroupId) && adminGroupId === group.groupId && (
                        <Tooltip
                          placement="right"
                          title="Adding users to this group will grant administrative access for this project"
                        >
                          <Chip size="small" color="primary" label="Admin Group" sx={{ cursor: 'default' }} />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={{ width: '5%' }}>
                    <Checkbox
                      // TODO remove this limitation to select only one product group at a time after Product API can delete multiple groups at a time
                      disabled={
                        itemType === AdminItemType.Product &&
                        selectedGroupIds.length > 0 &&
                        selectedGroupIds?.[0] !== group.groupId
                      }
                      checked={selectedGroupIds.includes(group.groupId)}
                      onChange={(event) => selectGroup(group.groupId, event)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isCreateModalOpen ? (
        <GroupCreateModal
          type={itemType}
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          itemId={Number(itemId)}
        />
      ) : null}
    </>
  );
};
