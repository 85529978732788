import { FC, PropsWithChildren, useMemo } from 'react';
import { useAppSelector } from '@/store/useAppSelector';
import { useCurrentProductId } from '@/features/admin/hooks/useCurrentProductId';
import { AdminItemType } from '@/features/admin/types';
import { UserAPI } from '@/services/api/UserAPI';
import { GroupAdminRouteGuard } from '../GroupAdminRouteGuard/GroupAdminRouteGuard';

export const ProductGroupAdminRouteGuard: FC<PropsWithChildren> = ({ children }) => {
  const productId = useCurrentProductId();
  const { data: products, isLoading, isError } = UserAPI.useGetProductsWithAccess();
  const user = useAppSelector((state) => state.auth.user);

  const isGroupAdmin = useMemo(() => {
    const product = products?.find((pr) => pr.productId === productId);
    return product?.isProductAdmin || user?.isSuperAdmin === 'True';
  }, [productId, products, user?.isSuperAdmin]);

  return (
    <GroupAdminRouteGuard
      isGroupAdmin={isGroupAdmin}
      isLoading={isLoading}
      isError={isError}
      itemType={AdminItemType.Product}
    >
      {children}
    </GroupAdminRouteGuard>
  );
};
