import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { isNonNullable } from '@/utils/filters';

const isOrderByEqual = <K>(orderByA: K[] | undefined = [], orderByB: K[] | undefined = []): boolean =>
  orderByA.concat().sort().toString() === orderByB.concat().sort().toString();

const getNonEmptyProperty = <T, K extends keyof T>(item: T, properties: K[]): T[K] | undefined => {
  for (const property of properties) {
    const value = item[property];
    if (isNonNullable(value) && (typeof value !== 'string' || !!value.length)) return value;
  }
  return undefined;
};

const getCompareFn =
  <T, K extends keyof T>(properties: K[], newDirection: 'asc' | 'desc') =>
  (itemA: T, itemB: T) => {
    const valueA = getNonEmptyProperty(itemA, properties);
    const valueB = getNonEmptyProperty(itemB, properties);

    let order = 0;

    // sort in `asc` (by guessing the type of the value for now)
    // could let the caller pass a data type from the `sort()` function
    if (typeof valueA === 'string' || typeof valueB === 'string') {
      const dateA = dayjs(String(valueA ?? ''));
      const dateB = dayjs(String(valueB ?? ''));
      if (dateA.isValid() || dateB.isValid()) {
        order = dateA.valueOf() - dateB.valueOf();
      } else {
        order = String(valueA ?? '').localeCompare(String(valueB ?? ''));
      }
    } else {
      order = Number(valueA) - Number(valueB) || 0;
    }

    const isDesc = newDirection === 'desc';

    return order * (isDesc ? -1 : 1);
  };

type OrderDirection = 'asc' | 'desc';

export const useSortByProperties = <T, K extends keyof T = keyof T>(
  items: T[] | undefined,
  { initialOrderBy, initialDirection }: { initialOrderBy?: K[]; initialDirection?: OrderDirection } = {}
): {
  sorted: T[] | undefined;
  orderBy: K[] | undefined;
  direction: OrderDirection | undefined;
  getIsActive: (properties: K[]) => boolean;
  getDirection: (properties: K[]) => OrderDirection | undefined;
  sort: (properties: K[], direction?: OrderDirection) => void;
  reset: () => void;
} => {
  const [sortConditions, setSortConditions] = useState<[K[] | undefined, OrderDirection | undefined]>([
    initialOrderBy,
    initialDirection,
  ]);

  const sorted: T[] | undefined = useMemo(() => {
    const [orderBy, direction] = sortConditions;
    if (!items || !orderBy || !direction) return items;
    // clone the array to avoid mutating the original array
    return items.concat().sort(getCompareFn(orderBy, direction));
  }, [items, sortConditions]);

  const reset = useCallback((): void => {
    setSortConditions([initialOrderBy, initialDirection]);
  }, [initialDirection, initialOrderBy]);

  const sort = useCallback((targetOrderBy: K[], targetDirection?: OrderDirection): void => {
    setSortConditions(([currentOrderBy, currentDirection]) => {
      let newDirection: OrderDirection;
      if (targetDirection) {
        newDirection = targetDirection;
      } else if (!currentDirection || !isOrderByEqual(currentOrderBy, targetOrderBy)) {
        newDirection = 'asc';
      } else {
        newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
      }

      return [targetOrderBy, newDirection];
    });
  }, []);

  const getIsActive = useCallback(
    (targetOrderBy: K[]): boolean => {
      const [orderBy] = sortConditions;
      return isOrderByEqual(orderBy, targetOrderBy);
    },
    [sortConditions]
  );

  const getDirection = useCallback(
    (targetOrderBy: K[]): OrderDirection | undefined => {
      const [orderBy, direction] = sortConditions;
      return isOrderByEqual(orderBy, targetOrderBy) ? direction : undefined;
    },
    [sortConditions]
  );

  return { sorted, orderBy: sortConditions[0], direction: sortConditions[1], getIsActive, getDirection, sort, reset };
};
