import { FC } from 'react';
import ControlledGrid from '@/ui/ControlledGrid';
import useGetGridColumns from '@/hooks/useGetGridColumns';
import { TermsAndConditionsFileNames } from '@/features/termsAndConditions/constants';
import TermsAndConditionsTile from '@/features/termsAndConditions/components/TermsAndConditionsTile';
import { BannerContent } from '@/ui/HeaderBanner';
import GlobalLobbyLayout from '@/pages/layouts/GlobalLobbyLayout';
import { BannerData } from '@/ui/HeaderBanner/types';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const TermsAndConditionsBannerData: Partial<BannerData> = {
  image: 'https://res.cloudinary.com/mott-macdonald/image/upload/v1673265802/Moata%20banners/Roads_yjsdvw.jpg',
  heading: 'Moata Legal Centre',
};

export const TermsAndConditions: FC = () => {
  useDocumentTitle('Moata Legal Centre');

  const columns = useGetGridColumns();

  return (
    <GlobalLobbyLayout>
      <BannerContent bannerData={TermsAndConditionsBannerData} />
      <ControlledGrid columns={columns}>
        {TermsAndConditionsFileNames?.map((termAndCons) => (
          <TermsAndConditionsTile
            key={termAndCons.title}
            title={termAndCons.title}
            description={termAndCons.description}
            url={`/legal-files/${encodeURIComponent(termAndCons.filename)}`}
          />
        ))}
      </ControlledGrid>
    </GlobalLobbyLayout>
  );
};
