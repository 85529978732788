import { Action, CreateProductActionDto } from '@mottmac-moata/identity-sdk';
import { MutateOptions } from 'react-query';
import { AdminItemType } from '@/features/admin/types';
import { ProductActionAPI } from '@/services/api/ProductActionAPI';
import { ProjectActionAPI } from '@/services/api/ProjectActionAPI';

export const useCreateAction = (
  itemType: AdminItemType
): {
  isLoading: false | true;
  isError: false | true;
  createAction: (
    variables: {
      productId: number;
      data: CreateProductActionDto;
    },
    options?: MutateOptions<
      Action,
      unknown,
      {
        productId: number;
        data: CreateProductActionDto;
      },
      unknown
    >
  ) => Promise<Action>;
  error: unknown;
} => {
  const {
    mutateAsync: createProjectAction,
    isLoading: isProjectActionLoading,
    isError: isProjectActionError,
    error: projectError,
  } = ProjectActionAPI.useCreate();
  const {
    mutateAsync: createProductAction,
    isLoading: isProductActionLoading,
    isError: isProductActionError,
    error: productError,
  } = ProductActionAPI.useCreate();

  return {
    createAction: itemType === AdminItemType.Project ? createProjectAction : createProductAction,
    isLoading: isProductActionLoading || isProjectActionLoading,
    isError: isProductActionError || isProjectActionError,
    error: projectError || productError,
  };
};
