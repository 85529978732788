import { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import HeaderBanner from '@/ui/HeaderBanner';
import ProductsList from '@/features/marketplace/components/ProductsList';
import { useAppDispatch } from '@/store/useAppDispatch';
import { setPageIsLoaded } from '@/store/loading';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const Marketplace: FC = () => {
  useDocumentTitle('Marketplace');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageIsLoaded(false));
  }, [dispatch]);

  return (
    <Stack sx={{ flex: 1 }}>
      <HeaderBanner page="marketplace" />
      <ProductsList onLoaded={() => dispatch(setPageIsLoaded(true))} />
    </Stack>
  );
};

export default Marketplace;
