import { FC } from 'react';
import { Stack } from '@mui/material';
import MessageSnackbar from '@/ui/MessageSnackbar';
import TermsAndConditions from '@/ui/TermsAndConditions';
import AppRouter from './router/AppRouter';
import { useAutoRefreshTokens } from './features/auth/hooks/useAutoRefreshTokens';

const AppContent: FC = () => {
  useAutoRefreshTokens();

  return (
    <Stack sx={{ minHeight: '100vh', minWidth: '375px' }}>
      <Stack sx={{ flex: 1 }}>
        <AppRouter />
      </Stack>
      <MessageSnackbar />
      <TermsAndConditions />
    </Stack>
  );
};

export default AppContent;
