import { UserProjectDto } from '@mottmac-moata/identity-sdk';
import { ComponentProps, FC, useRef } from 'react';
import uniqueid from 'lodash.uniqueid';
import { useInfiniteScroll, useInViewport, useLatest, useSet } from 'ahooks';
import { Result, NoSearchResult } from '@/ui/Result';
import ControlledGrid from '@/ui/ControlledGrid';
import LoaderTile from '@/ui/LoaderTile';
import ProjectTile from '@/features/lobby/components/ProjectTile';
import useGetGridColumns from '@/hooks/useGetGridColumns';

const PAGE_SIZE = 24; // 24 can show full rows for all `columns` sizes

const getNextPageFromList = async (
  projects?: UserProjectDto[],
  startIndex = 0,
  endIndex = startIndex + PAGE_SIZE
): Promise<PageState> => {
  const list = projects?.slice(startIndex, endIndex) ?? [];
  return { list, nextIndex: endIndex };
};

const getLastIndexOfPage = (indexSet: Set<number>, pageSize: number): number => {
  const sortedIndexArray = Array.from(indexSet).sort((a, b) => a - b);
  const maxIndex = sortedIndexArray[sortedIndexArray.length - 1] ?? 0;
  return (Math.floor(maxIndex / pageSize) + 1) * pageSize;
};

const ProjectTileWithIntersectionCheck: FC<
  ComponentProps<typeof ProjectTile> & { onInViewportChange: (inViewport: boolean) => void }
> = ({ onInViewportChange, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  useInViewport(ref.current, {
    callback: (entry: IntersectionObserverEntry) => {
      onInViewportChange(entry.isIntersecting);
    },
  });

  return <ProjectTile {...props} ref={ref} />;
};

interface PageState {
  list: UserProjectDto[];
  nextIndex?: number;
}

interface LazyRenderProjectsGridProps {
  projects?: UserProjectDto[];
  isLoading?: boolean;
  isEmptyDataResult?: boolean;
  isNoSearchResult?: boolean;
  userId?: string;
  isSuperAdmin?: boolean;
}

const LazyRenderProjectsGrid: FC<LazyRenderProjectsGridProps> = ({
  projects,
  isLoading = false,
  isEmptyDataResult = false,
  isNoSearchResult = false,
  userId,
  isSuperAdmin = false,
}) => {
  const columns = useGetGridColumns();

  const [
    inViewportTileIndexSet,
    { add: addToInViewportTileIndexSet, remove: removeFromInViewportTileIndexSet, reset: resetInViewportTileIndexSet },
  ] = useSet<number>([]);
  const inViewportTileIndexSetRef = useLatest(inViewportTileIndexSet);

  const { data: pageState, loadingMore: isLoadingMorePage } = useInfiniteScroll<PageState>(
    (currentPageState) => {
      // next page
      if (currentPageState) {
        return getNextPageFromList(projects, currentPageState.nextIndex);
      }

      // initial load
      const lastInViewportEndIndex = getLastIndexOfPage(inViewportTileIndexSetRef.current, PAGE_SIZE);
      resetInViewportTileIndexSet();
      return getNextPageFromList(projects, 0, lastInViewportEndIndex);
    },
    {
      target: document,
      threshold: 200,
      isNoMore: (d) => d?.nextIndex === undefined || d.nextIndex >= (projects?.length ?? 0),
      reloadDeps: [projects],
    }
  );

  if (isEmptyDataResult) {
    return <Result severity="info" title="No projects found" />;
  }

  if (isNoSearchResult) {
    return <NoSearchResult />;
  }

  return (
    <ControlledGrid columns={columns}>
      {pageState?.list?.map((project, index) => (
        <ProjectTileWithIntersectionCheck
          key={project.projectId}
          project={project}
          userId={userId}
          isSuperAdmin={isSuperAdmin}
          onInViewportChange={(inViewport) => {
            if (inViewport) {
              addToInViewportTileIndexSet(index);
            } else {
              removeFromInViewportTileIndexSet(index);
            }
          }}
        />
      ))}
      {(isLoading || isLoadingMorePage) &&
        [...Array(columns)].map(() => (
          <LoaderTile
            key={uniqueid('ProjectTileLoaderKey_')}
            mediaHeight={184}
            mediaAlt="This is a project tile loading"
          />
        ))}
    </ControlledGrid>
  );
};

export default LazyRenderProjectsGrid;
