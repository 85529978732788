import { ChangeEvent, useCallback, useState } from 'react';
import pluralize from 'pluralize';
import { ProjectGroupAPI } from '@/services/api/ProjectGroupAPI';
import { ProductGroupAPI } from '@/services/api/ProductGroupAPI';
import { AdminItemType } from '@/features/admin/types';
import { setMessage } from '@/store/messages';
import { useAppDispatch } from '@/store/useAppDispatch';
import { useDeleteConfirmation } from './useDeleteConfirmation';

export const useGroupDelete = (
  type: AdminItemType,
  itemId: number
): {
  selectedGroupIds: string[];
  selectGroup: (selectedId: string, event?: ChangeEvent<HTMLInputElement>) => void;
  deleteGroup: () => Promise<void>;
  openDeleteConfirmation: () => void;
} => {
  const dispatch = useAppDispatch();

  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const { mutateAsync: deleteProjectGroups } = ProjectGroupAPI.useDeleteMany();
  const { mutateAsync: deleteProductGroup } = ProductGroupAPI.useDelete();

  const selectGroup = useCallback(
    (selectedId: string, event?: ChangeEvent<HTMLInputElement>): void => {
      event?.stopPropagation?.();
      setSelectedGroupIds(
        event?.target?.checked ? [...selectedGroupIds, selectedId] : selectedGroupIds.filter((id) => id !== selectedId)
      );
    },
    [selectedGroupIds]
  );

  const deleteGroup = useCallback(async (): Promise<void> => {
    if (type === AdminItemType.Project) {
      await deleteProjectGroups({ groupIds: selectedGroupIds, projectId: Number(itemId) });
    } else {
      // Using selectedGroupIds?.[0] here as soon we will have API endpoint to delete multiple groups at once.
      // Until then, we will just keep one id in the list
      await deleteProductGroup({ groupId: selectedGroupIds?.[0], productId: Number(itemId) });
    }
    setSelectedGroupIds([]);
    dispatch(
      setMessage({
        severity: 'success',
        content: `${selectedGroupIds.length} ${pluralize('group', selectedGroupIds.length)} deleted`,
      })
    );
    setSelectedGroupIds([]);
  }, [deleteProductGroup, deleteProjectGroups, dispatch, itemId, selectedGroupIds, type]);

  const { openDeleteConfirmation } = useDeleteConfirmation(deleteGroup, 'group', type, selectedGroupIds.length);

  return {
    selectedGroupIds,
    selectGroup,
    deleteGroup,
    openDeleteConfirmation,
  };
};
