import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useSet } from 'react-use';
import Modal from '@/ui/Modal';
import { ItemsList } from './ItemsList';
import { ItemManagementController, ItemsManagementTextConstants } from './types';

interface ItemsListManageModalProps {
  open: boolean;
  onClose: VoidFunction;
  ItemsController: ItemManagementController;
  constants: ItemsManagementTextConstants;
}

export const ItemsListManageModal: FC<ItemsListManageModalProps> = ({ open, onClose, ItemsController, constants }) => {
  const [selectedItemsProspectIds, ListProspectSelectionActions] = useSet<string>();
  const [selectedMemberIds, MemberSelectionActions] = useSet<string>();

  const onSave = useCallback(async () => {
    if (!ItemsController.memberItems) return;
    if (await ItemsController.onUpdate()) onClose();
  }, [ItemsController, onClose]);

  const handleOnClose = useCallback(() => {
    ItemsController.reset();
    onClose?.();
  }, [ItemsController, onClose]);

  const onAddMembers = useCallback(() => {
    ItemsController.addItems([...selectedItemsProspectIds.values()]);
    ListProspectSelectionActions.reset();
  }, [ListProspectSelectionActions, ItemsController, selectedItemsProspectIds]);

  const onRemoveMembers = useCallback(() => {
    ItemsController.removeItems([...selectedMemberIds.values()]);
    MemberSelectionActions.reset();
  }, [MemberSelectionActions, ItemsController, selectedMemberIds]);

  const onToggleAllMemberItems = useCallback(() => {
    if (selectedMemberIds.size === 0) {
      ItemsController.memberItems.forEach(({ id }) => MemberSelectionActions.add(id));
    } else MemberSelectionActions.reset();
  }, [selectedMemberIds.size, MemberSelectionActions, ItemsController.memberItems]);

  const onToggleAllProspects = useCallback(() => {
    if (selectedItemsProspectIds.size === 0) {
      ItemsController.itemProspects.forEach(({ id }) => ListProspectSelectionActions.add(id));
    } else ListProspectSelectionActions.reset();
  }, [selectedItemsProspectIds.size, ListProspectSelectionActions, ItemsController.itemProspects]);

  return (
    <Modal
      closable={!ItemsController.isLoadingMembers}
      message={
        ItemsController.error
          ? {
              content: ItemsController.error,
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={onClose}
      title={constants.title}
    >
      <Modal.Content>
        <Grid container justifyContent="center" alignItems="center" pl={1} pr={1} spacing={2}>
          <Grid item xs={12} lg={5}>
            <ItemsList
              selectedIds={selectedMemberIds}
              onToggle={MemberSelectionActions.toggle}
              onToggleAll={onToggleAllMemberItems}
              listName={constants.membersTitle}
              items={ItemsController.memberItems}
              title={constants.membersTitle}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid container direction={{ xs: 'row', lg: 'column' }} alignItems="center" justifyContent="center" gap={2}>
              <Tooltip title={constants.actionBtnTooltipTitle}>
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onAddMembers}
                    aria-label={constants.actionBtnTooltipTitle}
                    disabled={!selectedItemsProspectIds.size}
                  >
                    <Typography sx={{ transform: { xs: 'rotate(90deg)', lg: 'none' } }}>&lt;</Typography>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={constants.removeBtnTooltipTitle}>
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onRemoveMembers}
                    aria-label={constants.removeBtnTooltipTitle}
                    disabled={!selectedMemberIds.size}
                  >
                    <Typography sx={{ transform: { xs: 'rotate(90deg)', lg: 'none' } }}>&gt;</Typography>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <ItemsList
              selectedIds={selectedItemsProspectIds}
              onToggle={ListProspectSelectionActions.toggle}
              onToggleAll={onToggleAllProspects}
              listName={constants.allItemsTitle}
              items={ItemsController.itemProspects}
              title={constants.allItemsTitle}
            />
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="outlined" disabled={ItemsController.isLoading} onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          loading={ItemsController.isLoading}
          variant="contained"
          color="primary"
          disabled={!ItemsController.isDirty}
          onClick={onSave}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
