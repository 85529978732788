import { FC, PropsWithChildren } from 'react';
import Loader from '@/ui/Loader';
import { FetchErrorResult, Result } from '@/ui/Result';
import { AdminItemType } from '@/features/admin/types';

interface GroupAdminRouteGuardProps {
  itemType: AdminItemType;
  isLoading?: boolean;
  isError?: boolean;
  isGroupAdmin: boolean;
}
export const GroupAdminRouteGuard: FC<PropsWithChildren & GroupAdminRouteGuardProps> = ({
  children,
  isGroupAdmin,
  isError,
  isLoading,
  itemType,
}) => {
  if (isLoading) return <Loader flex={1} />;

  if (isError) {
    return <FetchErrorResult />;
  }

  if (!isGroupAdmin) return <Result severity="warning" title={`You are not an admin of this ${itemType}.`} />;

  return <>{children}</>;
};
