import { FC } from 'react';
import { TextField, IconButton, SxProps } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';

interface Props {
  filterValue: string;
  disabled?: boolean;
  onFilterValueChange: (newFilter: string) => void;
  dataGtmEvent?: string;
  label: string;
  name: string;
  sx?: SxProps;
}

const Search: FC<Props> = ({
  filterValue,
  onFilterValueChange,
  disabled = false,
  label,
  name,
  sx,
  dataGtmEvent = '',
}: Props) => (
  <TextField
    {...(dataGtmEvent ? { [dataGtmEvent]: dataGtmEvent } : {})}
    data-testid="search-input"
    label={label}
    variant="outlined"
    type="text"
    name={name}
    value={filterValue}
    onChange={(event) => onFilterValueChange(event.target.value)}
    disabled={disabled}
    sx={{
      width: { xs: '100%', sm: 280 },
      ...sx,
    }}
    slotProps={{
      input: {
        endAdornment: !filterValue ? (
          <SearchIcon data-testid="search-icon" />
        ) : (
          <IconButton
            aria-label="search-clear"
            sx={{ padding: 0 }}
            onClick={() => onFilterValueChange('')}
            data-testid="search-clear-button"
          >
            <CloseIcon />
          </IconButton>
        ),
      },
    }}
  />
);

export default Search;
