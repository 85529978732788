import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import type { ProjectDto, UpdateProjectDto } from '@mottmac-moata/identity-sdk';
import useAuth from '@/hooks/useAuth';
import { authorisedIdentityApiClient } from '@/services/api/identityApiClient';
import { UserAPI } from '@/services/api/UserAPI';

const useUpdateProject = (): UseMutationResult<ProjectDto, unknown, { projectId: number; data: UpdateProjectDto }> => {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: { projectId: number; data: UpdateProjectDto }) =>
      authorisedIdentityApiClient(await getAccessToken()).Project.update(data.projectId, data.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: UserAPI.queryKeys.projects,
      });
    },
  });
};

export default useUpdateProject;
