import { useCallback } from 'react';
import { AdminItemType } from '@/features/admin/types';
import { ProjectUserAPI } from '@/services/api/ProjectUserAPI';
import { ProductGroupAPI } from '@/services/api/ProductGroupAPI';

export const useUpdateGroupUsers = (
  itemType: AdminItemType,
  itemId: number,
  groupId: string
): { isLoading: boolean; isError: boolean; update: (userIds: string[]) => Promise<void> } => {
  const {
    mutateAsync: updateProjectUser,
    isLoading: isUpdateProjectLoading,
    isError: isUpdateProjectError,
  } = ProjectUserAPI.useSetUsersByGroupId();

  const {
    mutateAsync: updateProductUser,
    isLoading: isUpdateProductLoading,
    isError: isUpdateProductError,
  } = ProductGroupAPI.useSetUsers();

  const update = useCallback(
    async (userIds: string[]): Promise<void> => {
      if (itemType === AdminItemType.Project) {
        await updateProjectUser({ projectId: itemId, groupId, userIds });
      } else {
        await updateProductUser({ productId: itemId, groupId, userIds });
      }
    },
    [groupId, itemId, itemType, updateProductUser, updateProjectUser]
  );

  return {
    update,
    isLoading: isUpdateProjectLoading || isUpdateProductLoading,
    isError: isUpdateProjectError || isUpdateProductError,
  };
};
