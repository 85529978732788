import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import useGetScreenSize from '@/hooks/useGetScreenSize';
import { LoadingSpinner } from '@/ui/LoadingSpinner';

/**
 * A spinner to show while the user is being authenticated.
 *
 * Designed to be used in the `AuthLayout`.
 */
export const AuthLoadingSpinner: FC = () => {
  const { isXs } = useGetScreenSize('only');

  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        p: isXs ? 2 : 5,
      }}
    >
      <LoadingSpinner />
      <Typography component="span">Authenticating...</Typography>
    </Stack>
  );
};
