import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { FC, useState } from 'react';
import { ListItem } from './types';

interface ListProps {
  title: string;
  items: ListItem[];
  onToggle: (id: string) => void;
  onToggleAll: () => void;
  listName: string;
  selectedIds: Set<string>;
}

export const ItemsList: FC<ListProps> = ({ selectedIds, title, items, onToggle, onToggleAll, listName }) => {
  const [filterValue, setFilterValue] = useState('');

  const filteredItems = items.filter(({ name, email }) => {
    const lowercaseFilterValue = filterValue.toLowerCase();
    return name?.toLowerCase().includes(lowercaseFilterValue) || email?.toLowerCase().includes(lowercaseFilterValue);
  });

  const isAllSelected = !!(items.length && items.length === selectedIds.size);
  const isSomeSelected = selectedIds.size > 0;

  return (
    <Paper data-testid={listName} sx={{ overflow: 'hidden' }}>
      <Card>
        <CardHeader
          onClick={onToggleAll}
          sx={{ pb: 0, textTransform: 'uppercase', cursor: 'pointer' }}
          avatar={
            <Checkbox
              inputProps={{ 'aria-label': 'Select all' }}
              disabled={!items.length}
              checked={isAllSelected}
              indeterminate={!isAllSelected && isSomeSelected}
            />
          }
          title={title}
        />

        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder="Search"
          sx={{ pl: 2, pr: 2, pb: 2 }}
        />
        <Divider />
        <List
          dense
          component="div"
          role="list"
          sx={{ overflowY: 'auto', overflowX: 'hidden', height: 400, minWidth: { md: 400 } }}
          aria-label={listName}
        >
          {filteredItems?.map((item) => {
            return (
              <ListItemButton divider role="listitem" key={item.id} onClick={() => onToggle(item.id)}>
                <ListItemIcon>
                  <Checkbox disableRipple checked={selectedIds.has(item.id)} />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  secondary={item?.email}
                  slotProps={{
                    primary: {
                      sx: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
                    },

                    secondary: {
                      sx: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
                    },
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    </Paper>
  );
};
