import { Link as RouterLink } from 'react-router-dom';
import { Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { AppFullRoutePath, AppPage } from '@/router';
import useAuth from '@/hooks/useAuth';
import { GTMEvents, trackGTMEvent } from '@/services/telemetry/gtm';
import { useUserInfo } from '../useUserInfo';

const StdUserMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}> = ({ anchorElement, handleClose }) => {
  const { isAuthenticated } = useAuth();

  const userInfo = useUserInfo();

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: { sx: { minWidth: '180px' } },
        list: { component: 'div' },
      }}
    >
      {isAuthenticated ? (
        <Stack sx={{ px: 2, py: 0.75 }}>
          <Typography color="text.secondary" variant="caption">
            {userInfo.displayName}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {userInfo.email}
          </Typography>
        </Stack>
      ) : null}
      {isAuthenticated ? (
        <MenuItem
          component={RouterLink}
          to={AppFullRoutePath[AppPage.Logout]}
          onClick={() => {
            trackGTMEvent(GTMEvents.header.accountItemLogoutClick());
            handleClose();
          }}
        >
          Log out
        </MenuItem>
      ) : (
        <MenuItem component={RouterLink} to={AppFullRoutePath[AppPage.Login]} onClick={handleClose}>
          Log in
        </MenuItem>
      )}
      <Divider />
      <MenuItem component={RouterLink} to={AppFullRoutePath[AppPage.TermsAndConditions]} onClick={handleClose}>
        Terms and Conditions
      </MenuItem>
      <MenuItem component={RouterLink} to={AppFullRoutePath[AppPage.TermsAndConditions]} onClick={handleClose}>
        Privacy policy
      </MenuItem>
      <Stack sx={{ px: 2, py: 0.75 }}>
        <Typography color="text.secondary" variant="caption">
          &copy; {new Date().getFullYear()} Mott MacDonald
        </Typography>
      </Stack>
    </Menu>
  );
};

export default StdUserMenu;
