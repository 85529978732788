import { FC, PropsWithChildren } from 'react';
import { Box, Paper } from '@mui/material';
import { useCloudinaryImage } from '@mott-macdonald/smi-react-ui-kit';
import MoataLogo from '@/assets/images/moata-logo.svg?react';
import useGetScreenSize from '@/hooks/useGetScreenSize';
import { AUTH_BACKGROUND_IMAGE_URL } from '@/configs';

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isXs } = useGetScreenSize('only');

  const { imageRef, transformedImageUrl } = useCloudinaryImage({
    imageUrl: AUTH_BACKGROUND_IMAGE_URL,
  });

  return (
    <Box
      ref={imageRef}
      sx={{
        flex: 1,
        backgroundImage: `url(${transformedImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        sx={{
          width: 400,
          display: 'flex',
          flexDirection: 'column',
          m: 2,
        }}
        elevation={1}
      >
        <Box
          sx={{
            backgroundColor: 'primary.main',
            p: isXs ? 2 : 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: isXs ? 'flex-start' : 'center',
          }}
        >
          <MoataLogo />
        </Box>
        {children}
      </Paper>
    </Box>
  );
};

export default AuthLayout;
