import { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Divider, Stack, Typography, List, ListItem, Link } from '@mui/material';
import { UserProjectDto } from '@mottmac-moata/identity-sdk';
import { RouteBuilder } from '@/router';
import { AdminItemType } from '@/features/admin/types';

interface Props {
  projectSummary: ReactNode;
  admins: UserProjectDto['admins'];
  projectId: UserProjectDto['projectId'];
  isCurrentUserAdmin: boolean;
}

const DetailsTab: FC<Props> = ({ projectSummary, admins, projectId, isCurrentUserAdmin }: Props) => {
  return (
    <Stack divider={<Divider />} spacing={3} paddingY={3} paddingX={3}>
      {projectSummary ? (
        <Typography component="div" sx={{ wordBreak: 'break-word' }}>
          {projectSummary}
        </Typography>
      ) : null}

      {admins && (
        <Stack spacing={1}>
          <Typography variant="subtitle1">Admins</Typography>

          <List dense>
            {admins.map((admin) => (
              <ListItem key={admin.userId} disableGutters>
                <Typography>{`${admin.givenName} ${admin.surname}`}</Typography>
              </ListItem>
            ))}
          </List>

          {isCurrentUserAdmin && (
            <Link
              component={RouterLink}
              data-gtm-admin-view={projectId}
              to={RouteBuilder.admin(AdminItemType.Project, projectId)}
            >
              Admin view
            </Link>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DetailsTab;
