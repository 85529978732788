import { ProjectGroupAPI } from '@/services/api/ProjectGroupAPI';
import { useCurrentGroupId } from '@/features/admin/hooks/useCurrentGroupId';
import { useCurrentProjectId } from './useCurrentProjectId';

export const useCurrentProjectGroup = (
  options?: Parameters<typeof ProjectGroupAPI.useGetById>[2]
): ReturnType<typeof ProjectGroupAPI.useGetById> => {
  const projectGroupId = useCurrentGroupId();
  const projectId = useCurrentProjectId();

  return ProjectGroupAPI.useGetById(Number(projectId), String(projectGroupId), {
    enabled: !!projectId && !!projectGroupId,
    ...options,
  });
};
