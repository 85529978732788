import { FC } from 'react';
import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import Private from '@/ui/Private';

import { AuthLayoutRoute } from '@/pages/layouts/AuthLayout';
import { GlobalLobbyLayoutRoute } from '@/pages/layouts/GlobalLobbyLayout';

import AuthCallbackHandler from '@/pages/auth/AuthCallbackHandler';
import Login from '@/pages/auth/Login';
import Logout from '@/pages/auth/Logout';
import LogoutConfirmation from '@/pages/auth/LogoutConfirmation';
import UserProfileUpdate from '@/pages/auth/UserProfileUpdate';

import Lobby from '@/pages/lobby/Home';
import Workspace from '@/pages/lobby/Workspace';
import Resources from '@/pages/lobby/Resources';
import Marketplace from '@/pages/lobby/Marketplace';
import AdminRouter from '@/pages/admin/AdminRouter';

import TermsAndConditions from '@/pages/TermsAndConditions';

import { AppRouteSegment } from '@/router';

const Routes: FC = () => {
  return (
    <ReactRouterRoutes>
      <Route
        path={AppRouteSegment.Root}
        element={
          <Private>
            <GlobalLobbyLayoutRoute />
          </Private>
        }
      >
        <Route index element={<Lobby />} />
        <Route path={AppRouteSegment.MarketPlace} element={<Marketplace />} />
        <Route path={AppRouteSegment.Workspace} element={<Workspace />} />
        <Route path={AppRouteSegment.Resources} element={<Resources />} />

        <Route path={`${AppRouteSegment.Admin}/${AppRouteSegment.Wildcard}`} element={<AdminRouter />} />
      </Route>

      <Route path={AppRouteSegment.Callback} element={<AuthLayoutRoute />}>
        <Route index element={<AuthCallbackHandler />} />
      </Route>

      <Route path={`${AppRouteSegment.User}/${AppRouteSegment.Update}`} element={<AuthLayoutRoute />}>
        <Route index element={<UserProfileUpdate />} />
      </Route>

      <Route path={AppRouteSegment.Login} element={<AuthLayoutRoute />}>
        <Route index element={<Login />} />
      </Route>

      <Route path={AppRouteSegment.Logout} element={<AuthLayoutRoute />}>
        <Route path={AppRouteSegment.Confirmation} element={<LogoutConfirmation />} />
        <Route index element={<Logout />} />
      </Route>

      <Route path={AppRouteSegment.TermsAndConditions} element={<TermsAndConditions />} />

      <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppRouteSegment.Root} replace />} />
    </ReactRouterRoutes>
  );
};

export default Routes;
