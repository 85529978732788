import { SerializedError } from '@reduxjs/toolkit';
import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import { ProjectUserAPI } from '@/services/api/ProjectUserAPI';
import { AdminItemType } from '@/features/admin/types';
import { ProductUserAPI } from '@/services/api/ProductUserAPI';

export const useGetItemUsers = (
  itemType: AdminItemType,
  itemId: number
): { users?: UserSimpleDTO[]; isLoading: boolean; error?: SerializedError } => {
  const {
    data: projectUsers,
    isLoading: isLoadingProjectUsers,
    error: projectUsersError,
  } = ProjectUserAPI.useGetByProjectId(itemId, { enabled: itemType === AdminItemType.Project });
  const {
    data: productUsers,
    isLoading: isLoadingProductUsers,
    error: productUsersError,
  } = ProductUserAPI.useGetAll(itemId, { enabled: itemType === AdminItemType.Product });

  return {
    users: itemType === AdminItemType.Project ? projectUsers?.users : productUsers,
    isLoading: isLoadingProjectUsers || isLoadingProductUsers,
    error: projectUsersError || productUsersError || undefined,
  };
};
