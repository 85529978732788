import { FC } from 'react';
import { AppPage, RouteBuilder } from '@/router';
import { useCurrentProduct } from '@/features/admin/hooks/useCurrentProduct';
import { AdminItemType } from '@/features/admin/types';
import { GenericGroupAdminPageLayout } from '../GenericAdminPageLayout';

export const ProductGroupAdminPageLayout: FC = () => {
  const { data: currentProduct } = useCurrentProduct();

  return (
    <GenericGroupAdminPageLayout
      itemName={currentProduct?.name}
      itemType={AdminItemType.Product}
      linkUrl={RouteBuilder.admin(AdminItemType.Product, currentProduct?.productId ?? '')}
      configPage={AppPage.ProductConfiguration}
      groupConfigPage={AppPage.ProductGroupConfiguration}
    />
  );
};
