import { UseMutateAsyncFunction } from 'react-query';
import { AdminItemType } from '@/features/admin/types';
import { ProductActionAPI } from '@/services/api/ProductActionAPI';
import { ProjectActionAPI } from '@/services/api/ProjectActionAPI';

export const useDeleteAction = (
  itemType: AdminItemType
): {
  deleteAction: UseMutateAsyncFunction<void, unknown, { productId: number; actionId: string }, unknown>;
  isLoading: boolean;
  isError: boolean;
} => {
  const {
    mutateAsync: deleteProjectAction,
    isLoading: isProjectActionLoading,
    isError: isProjectActionError,
  } = ProjectActionAPI.useDeleteOne();
  const {
    mutateAsync: deleteProductAction,
    isLoading: isProductActionLoading,
    isError: isProductActionError,
  } = ProductActionAPI.useDeleteOne();

  return {
    deleteAction: itemType === AdminItemType.Project ? deleteProjectAction : deleteProductAction,
    isLoading: isProductActionLoading || isProjectActionLoading,
    isError: isProductActionError || isProjectActionError,
  };
};
