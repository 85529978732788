import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useRedirectIfLoggedIn from '@/features/auth/hooks/useRedirectIfLoggedIn';
import { useAppDispatch } from '@/store/useAppDispatch';
import { handleCallback } from '@/store/auth';
import { setMessage } from '@/store/messages';
import useAsyncThunkDispatch from '@/hooks/useAsyncThunkDispatch';
import { AppFullRoutePath, AppPage } from '@/router';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { AuthLoadingSpinner } from '@/features/auth/components/AuthLoadingSpinner/AuthLoadingSpinner';

const AuthCallbackHandler: FC = () => {
  useDocumentTitle('Authenticating...');

  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const { hash } = useLocation();
  const dispatch = useAppDispatch();

  const { dispatch: dispatchHandleCallback, error } = useAsyncThunkDispatch(handleCallback);
  const [isReadyForRedirect, setReadyForRedirect] = useState(false);

  useEffect(() => {
    if (error && error.name !== 'AbortError') {
      dispatch(
        setMessage({
          severity: 'error',
          content: 'There was a problem logging you in. If problem occurs contact your supervisor.',
        })
      );
      // replace as there is no need to navigate back
      navigate(AppFullRoutePath[AppPage.Login], { replace: true });
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    dispatchHandleCallback({ urlSearchParams, hash });
    setReadyForRedirect(true);
  }, [dispatchHandleCallback, urlSearchParams, hash]);

  useRedirectIfLoggedIn({ disabled: !isReadyForRedirect, handleRedirectSearchParamsFromPage: AppPage.Callback });

  return <AuthLoadingSpinner />;
};

export default AuthCallbackHandler;
