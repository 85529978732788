import { isRejected } from '@reduxjs/toolkit';
import { Middleware } from 'redux';

import parseAPIError from '@/utils/parseAPIError';

const moataSdkRequestErrorHandling: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // no idea why the typing thinks this is always truthy.... it is not :)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isRejected(action)) {
      parseAPIError(action.error, dispatch);
    }
    return next(action);
  };

export default moataSdkRequestErrorHandling;
