import { useCallback } from 'react';
import { useAppSelector } from '@/store/useAppSelector';
import { authorisedIdentityApiClient } from '@/services/api/identityApiClient';
import useAuth from '@/hooks/useAuth';
import { isNonNullable } from '@/utils/filters';
import { getJwtPolicy } from '@/utils/jwtHelpers';
import { CallbackRedirectAuthParam } from '../constants';

type CallbackRedirectAuthParams = Record<
  (typeof CallbackRedirectAuthParam)[keyof typeof CallbackRedirectAuthParam],
  string
>;

type GetCallbackRedirectAuthParams = (
  params?: {
    isProjectScopedTokenEnabled?: boolean;
    projectId?: number;
  },
  options?: { signal?: AbortSignal }
) => Promise<CallbackRedirectAuthParams>;

interface UseGetCallbackRedirectAuthParamsReturn {
  getCallbackRedirectAuthParams: GetCallbackRedirectAuthParams;
}

export const useGetCallbackRedirectAuthParams = (): UseGetCallbackRedirectAuthParamsReturn => {
  const { getTokens } = useAuth();
  const auth = useAppSelector((state) => state.auth);

  const getCallbackRedirectAuthParams: GetCallbackRedirectAuthParams = useCallback(
    async ({ isProjectScopedTokenEnabled = false, projectId } = {}, { signal } = {}) => {
      const b2cTokens = await getTokens(signal);

      if (isProjectScopedTokenEnabled) {
        if (!isNonNullable(projectId)) {
          throw new Error('`projectId` is required for project-scoped tokens');
        }

        const projectScopedTokens = await authorisedIdentityApiClient(
          b2cTokens.access_token
        ).OAuth2.getTokenProjectScoped({ projectId }, { signal });

        return {
          [CallbackRedirectAuthParam.AccessToken]: projectScopedTokens.access_token,
          [CallbackRedirectAuthParam.RefreshToken]: projectScopedTokens.refresh_token,
          [CallbackRedirectAuthParam.Policy]: getJwtPolicy(projectScopedTokens.access_token),
          [CallbackRedirectAuthParam.LoginUrl]: auth.projectLoginUrl ?? '',
          [CallbackRedirectAuthParam.LogoutUrl]: auth.projectLogoutUrl ?? '',
        };
      }

      return {
        [CallbackRedirectAuthParam.AccessToken]: b2cTokens.access_token,
        [CallbackRedirectAuthParam.RefreshToken]: b2cTokens.refresh_token,
        [CallbackRedirectAuthParam.Policy]: getJwtPolicy(b2cTokens.access_token),
        [CallbackRedirectAuthParam.LoginUrl]: auth.projectLoginUrl ?? '',
        [CallbackRedirectAuthParam.LogoutUrl]: auth.projectLogoutUrl ?? '',
      };
    },
    [auth.projectLoginUrl, auth.projectLogoutUrl, getTokens]
  );

  return { getCallbackRedirectAuthParams };
};
