import { FC } from 'react';
import { Box } from '@mui/material';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';

const Resources: FC = () => {
  useDocumentTitle('Resources');

  return <Box p={3}>This is the Resources</Box>;
};

export default Resources;
