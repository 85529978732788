import { Button, ButtonProps } from '@mui/material';
import { ComponentProps, FC, ReactNode } from 'react';
import Modal from '@/ui/Modal';

export interface DialogProps {
  type: 'confirm' | 'alert';
  open?: boolean;
  title?: string;
  content?: ReactNode;
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  onCancel?: VoidFunction;
  okText?: string;
  okButtonProps?: ButtonProps;
  onOk?: VoidFunction;
  autoFocusButton?: 'ok' | 'cancel' | false;
  closable?: boolean;
  message?: ComponentProps<typeof Modal>['message'];
}

const Dialog: FC<DialogProps> = ({
  type,
  open = false,
  title = '',
  content,
  cancelText = 'Cancel',
  cancelButtonProps,
  onCancel,
  okText = 'OK',
  okButtonProps,
  onOk,
  autoFocusButton = 'ok',
  closable = false,
  message,
}) => {
  return (
    <Modal open={open} title={title} onCancel={onCancel} closable={closable} message={message}>
      <Modal.Content>
        {typeof content === 'string' ? <Modal.ContentText>{content}</Modal.ContentText> : content}
      </Modal.Content>
      <Modal.Actions>
        {type === 'confirm' ? (
          <Button
            variant="outlined"
            color="primary"
            {...cancelButtonProps}
            onClick={onCancel}
            autoFocus={autoFocusButton === 'cancel'}
          >
            {cancelText}
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          {...okButtonProps}
          onClick={onOk}
          autoFocus={autoFocusButton === 'ok'}
        >
          {okText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default Dialog;
