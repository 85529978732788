import { FC, ReactElement, useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthLoadingPage } from '@/features/auth/components/AuthLoadingPage/AuthLoadingPage';
import useAuth from '@/hooks/useAuth';
import { AppFullRoutePath, AppPage, LoginPageSearchParam } from '@/router';
import { UserAPI } from '@/services/api/UserAPI';
import { sendRedirectUriUsageEvent } from '@/services/telemetry/sentry/events';
import { setMessage } from '@/store/messages';
import { useAppDispatch } from '@/store/useAppDispatch';
import { getErrorMessage, isAbortError } from '@/utils/errors';
import { toRelativeLocationString } from '@/utils/url/toRelativeLocationString';

interface Props {
  children: ReactElement;
}

const Private: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [urlSearchParams] = useSearchParams();
  const { isAuthenticated, refreshTokens } = useAuth();

  const hasThirdPartyRedirectUri = urlSearchParams.has(LoginPageSearchParam.RedirectUri);

  const goToLoginPage = useCallback((): void => {
    // This `Private` component is protecting Identity's own pages within the App,
    // so we bring the target in-app location to the login page.
    const state = toRelativeLocationString(location);
    const isHomePage = state === AppFullRoutePath[AppPage.Home];
    const search = !isHomePage ? `?${new URLSearchParams({ [LoginPageSearchParam.State]: state }).toString()}` : '';
    navigate(`${AppFullRoutePath[AppPage.Login]}${search}`, { replace: true });
  }, [location, navigate]);

  // resume session
  useEffect(() => {
    // don't do anything when `hasThirdPartyRedirectUri` is true,
    // to make sure third-party redirect will always work as the first priority.
    if (isAuthenticated || hasThirdPartyRedirectUri) return undefined;

    // if the local session is not authenticated,
    // try to resume the session from remote by refreshing the tokens.
    const abortController = new AbortController();
    refreshTokens(abortController.signal).catch((error) => {
      if (isAbortError(error)) return;
      dispatch(setMessage({ severity: 'error', content: getErrorMessage(error) }));
      goToLoginPage();
    });

    return () => {
      abortController.abort();
    };
  }, [dispatch, goToLoginPage, hasThirdPartyRedirectUri, isAuthenticated, refreshTokens]);

  // DANGER:
  // We have to trigger the `/User/me` endpoint to have the user's:
  //   - pending invitations accepted.
  //   - last login time updated.
  UserAPI.useFetchMe();

  // for the existing scenario, check out the comment at {@link LoginPageSearchParam.RedirectUri}.
  // external apps should only redirect to Identity's **login** page with their B2C overriding params.
  // UPDATED:
  // This is an existing misused case by the "Moata Inspect" mobile app (iOS+Android).
  // They have released a new version that redirects to the login page instead.
  // but we still have to keep this for the old versions for a while. (check on Sentry for the remaining usages.)
  if (hasThirdPartyRedirectUri) {
    sendRedirectUriUsageEvent({ landingUrl: window.location.href, referrerUrl: document.referrer });
    return <Navigate to={`${AppFullRoutePath[AppPage.Login]}${location.search}`} replace />;
  }

  if (!isAuthenticated) {
    return <AuthLoadingPage />;
  }

  return children;
};

export default Private;
