import { AppRouteSegment } from '@/router/constants';

export const AdminItemConfigurationPageTabs = {
  Groups: AppRouteSegment.Groups,
  Users: AppRouteSegment.Users,
  Actions: AppRouteSegment.Actions,
} as const;

export const AdminItemConfigurationPageTabLabels = {
  [AdminItemConfigurationPageTabs.Groups]: {
    searchLabel: 'Search groups',
    searchName: 'searchGroups',
    dataGtmEvent: 'group-search',
    buttonLabel: 'Group',
  },
  [AdminItemConfigurationPageTabs.Users]: {
    searchLabel: 'Search users',
    searchName: 'searchUsers',
    dataGtmEvent: 'group-users',
    buttonLabel: 'Users',
  },
  [AdminItemConfigurationPageTabs.Actions]: {
    searchLabel: 'Search actions',
    searchName: 'searchActions',
    dataGtmEvent: 'group-actions',
    buttonLabel: 'Actions',
  },
} as const;

// This list will be removed from back end
export const ReservedProjectActionNames = ['ADMIN', 'ACCESS'];
export const ReservedProductActionNames = ['Product Admin', 'Project Creator', 'Product Reader'];
