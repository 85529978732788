import { FC, memo } from 'react';
import { Card, CardMedia, Box } from '@mui/material';
import { preloadImage } from '@mott-macdonald/smi-react-ui-kit';

interface LoaderTileProps {
  mediaHeight: number;
  mediaAlt: string;
}

/**
 * Used for products and projects for now
 */
const LoaderTile: FC<LoaderTileProps> = ({ mediaAlt, mediaHeight }: LoaderTileProps) => (
  <Card>
    <CardMedia component="img" height={mediaHeight} image={preloadImage} alt={mediaAlt} />
    <Box sx={{ height: 77, backgroundColor: 'background.default' }} data-testid="whiteBgBox" />
  </Card>
);

export default memo(LoaderTile);
