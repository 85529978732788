import { FC, MouseEvent, useState } from 'react';
import { Button } from '@mui/material';
import useGetScreenSize from '@/hooks/useGetScreenSize';
import { showChameleon } from '@/services/telemetry/chameleon';
import { GTMEvents, trackGTMEvent } from '@/services/telemetry/gtm';
import XsHelperMenu from './XsHelperMenu';
import StdHelperMenu from './StdHelperMenu';

const HelperMenu: FC = () => {
  const { isXs } = useGetScreenSize();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const openMenu = (e: MouseEvent<HTMLElement>): void => setAnchorElement(e.currentTarget);
  const closeMenu = (): void => setAnchorElement(null);

  const handleItemClick = ({ name }: { name: string }): void => {
    trackGTMEvent(GTMEvents.header.supportItemClick({ name }));
    closeMenu();
  };

  const handleShowChameleonHelpBarClick = ({ name }: { name: string }): void => {
    // ref: https://developers.chameleon.io/#/apis/search?id=trigger-search-api
    showChameleon('helpbar');
    trackGTMEvent(GTMEvents.header.supportItemClick({ name }));
    closeMenu();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ flexShrink: 0 }}
        onClick={(e) => {
          trackGTMEvent(GTMEvents.header.supportButtonClick());
          openMenu(e);
        }}
      >
        Support
      </Button>
      {isXs ? (
        <XsHelperMenu
          anchorElement={anchorElement}
          onClose={closeMenu}
          onItemClick={handleItemClick}
          onShowChameleonHelpBarClick={handleShowChameleonHelpBarClick}
        />
      ) : (
        <StdHelperMenu
          anchorElement={anchorElement}
          onItemClick={handleItemClick}
          onShowChameleonHelpBarClick={handleShowChameleonHelpBarClick}
        />
      )}
    </>
  );
};

export default HelperMenu;
