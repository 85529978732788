import { useMemo } from 'react';
import { useAppSelector } from '@/store/useAppSelector';
import { decodeAccessToken, IdentityAccessTokenPayload } from '@/utils/jwtHelpers';

export const useAccessTokenPayload = (): IdentityAccessTokenPayload | undefined => {
  const accessToken = useAppSelector(({ auth }) => auth.b2cTokens)?.access_token;

  const payload = useMemo(() => (accessToken ? decodeAccessToken(accessToken) : undefined), [accessToken]);

  return payload;
};
