import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Checkbox } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import pluralize from 'pluralize';
import { FetchErrorResult, NoSearchResult, Result } from '@/ui/Result';
import { HeaderCell } from '@/features/admin/components/HeaderCell';
import { ProjectUserAPI } from '@/services/api/ProjectUserAPI';
import { TableLoadingSkeleton } from '@/features/admin/components/LoadingSkeleton';
import { formatLastActiveDate, makeUserUIKey } from '@/features/admin/helpers';
import { HeaderSortLabel } from '@/features/admin/components/HeaderSortLabel';
import { useSortByProperties } from '@/hooks/useSortByProperties';
import { AdminItemConfigurationPageTabs } from '@/features/admin/constants';
import AdminItemConfigurationActionBar from '@/features/admin/components/AdminItemConfigurationActionBar';
import ProjectUserInviteGuide from '@/features/admin/components/ProjectUserInviteGuide';
import { setMessage } from '@/store/messages';
import { useAppDispatch } from '@/store/useAppDispatch';
import { useDeleteConfirmation } from '@/features/admin/hooks/useDeleteConfirmation';
import { AdminItemType } from '@/features/admin/types';
import { ProjectUserNameCell } from './ProjectUserNameCell';

export interface ProjectUserProps {
  projectId: number;
}

const isFiltered = (filterValue: string) => (user: UserSimpleDTO) =>
  user?.displayName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
  user?.email?.toLowerCase()?.includes(filterValue?.toLowerCase());

export const ProjectUsers: FC<ProjectUserProps> = ({ projectId }) => {
  const dispatch = useAppDispatch();
  const { data: { users } = {}, isLoading, error } = ProjectUserAPI.useGetByProjectId(Number(projectId));
  const { mutateAsync: deleteUsers } = ProjectUserAPI.useDeleteMany();

  const [selectedUsers, setSelectedUsers] = useState<UserSimpleDTO[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const filteredUsers = useMemo(
    () => (searchValue ? users?.filter(isFiltered(searchValue)) : users),
    [searchValue, users]
  );

  const onUserSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selectedUser: UserSimpleDTO): void => {
      event.stopPropagation();
      setSelectedUsers(
        event.target.checked
          ? [...selectedUsers, selectedUser]
          : selectedUsers.filter((user) => user.email !== selectedUser.email)
      );
    },
    [selectedUsers]
  );

  const onUsersDelete = useCallback(async (): Promise<void> => {
    const activeUserIds: string[] = [];
    const pendingUsersEmails: string[] = [];
    selectedUsers.forEach((user) => {
      if (user.userState === 'PENDING') {
        pendingUsersEmails.push(user.email);
      } else {
        activeUserIds.push(user.userId);
      }
    });
    await deleteUsers({
      payload: { activeUserIds, pendingUsersEmails },
      projectId: Number(projectId),
    });
    dispatch(
      setMessage({
        severity: 'success',
        content: `${selectedUsers.length} ${pluralize('user', selectedUsers.length)} deleted`,
      })
    );
    setSelectedUsers([]);
  }, [deleteUsers, dispatch, projectId, selectedUsers]);

  const { openDeleteConfirmation } = useDeleteConfirmation(
    onUsersDelete,
    'user',
    AdminItemType.Project,
    selectedUsers.length
  );

  const {
    sorted: filteredAndSortedUsers,
    sort: sortUsers,
    getIsActive,
    getDirection,
  } = useSortByProperties<UserSimpleDTO>(filteredUsers);

  const isErrorState = !isLoading && error;
  const isNoDataState = !isLoading && !error && !users?.length;
  const isNoSearchResultState = !isErrorState && !isNoDataState && !!searchValue && !filteredAndSortedUsers?.length;
  const isAbnormalState = isNoSearchResultState || isErrorState || isNoDataState;

  return (
    <>
      <Box data-testid="project-users" gap={4} display="flex" flexDirection="column">
        <AdminItemConfigurationActionBar
          tab={AdminItemConfigurationPageTabs.Users}
          searchLabel="Search users"
          searchValue={searchValue}
          onSearch={setSearchValue}
          createText="Invite User"
          onCreate={() => setIsCreateModalOpen(true)}
          deleteBtnTxt={`Delete ${selectedUsers.length > 0 ? selectedUsers.length : ''} ${pluralize('User', selectedUsers.length)}`}
          isDeleteDisabled={selectedUsers.length === 0}
          onDelete={openDeleteConfirmation}
        />
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="table of project user">
            <TableHead>
              <TableRow>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['displayName', 'surname', 'givenName', 'email'])}
                    direction={getDirection(['displayName', 'surname', 'givenName', 'email'])}
                    onClick={() => sortUsers(['displayName', 'surname', 'givenName', 'email'])}
                  >
                    USERS{filteredAndSortedUsers ? ` (${filteredAndSortedUsers.length})` : ''}
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['lastActive'])}
                    direction={getDirection(['lastActive'])}
                    onClick={() => sortUsers(['lastActive'])}
                  >
                    LAST ACTIVE
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['groupsCount'])}
                    direction={getDirection(['groupsCount'])}
                    onClick={() => sortUsers(['groupsCount'])}
                  >
                    GROUPS
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['authType'])}
                    direction={getDirection(['authType'])}
                    onClick={() => sortUsers(['authType'])}
                  >
                    AUTH TYPE
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['userState'])}
                    direction={getDirection(['userState'])}
                    onClick={() => sortUsers(['userState'])}
                  >
                    USER STATE
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell>
                  <HeaderSortLabel
                    active={getIsActive(['invitedBy'])}
                    direction={getDirection(['invitedBy'])}
                    onClick={() => sortUsers(['invitedBy'])}
                  >
                    INVITED BY
                  </HeaderSortLabel>
                </HeaderCell>
                <HeaderCell sx={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {isAbnormalState ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    {isNoDataState && <Result severity="info" title="There are no users assigned to this project." />}
                    {isNoSearchResultState && <NoSearchResult />}
                    {isErrorState && <FetchErrorResult />}
                  </TableCell>
                </TableRow>
              ) : null}

              {isLoading ? <TableLoadingSkeleton columns={6} /> : null}

              {filteredAndSortedUsers?.map((user) => (
                <TableRow key={makeUserUIKey(user)} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                  <ProjectUserNameCell user={user} projectId={projectId} />
                  <TableCell>{formatLastActiveDate(user)}</TableCell>
                  <TableCell>{user?.groupsCount}</TableCell>
                  <TableCell>{user?.authType}</TableCell>
                  <TableCell>{user?.userState}</TableCell>
                  <TableCell>{user?.invitedBy}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedUsers.includes(user)}
                      onChange={(event) => onUserSelect(event, user)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isCreateModalOpen ? (
        <ProjectUserInviteGuide
          projectId={Number(projectId)}
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      ) : null}
    </>
  );
};
