import { Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Action } from '@mottmac-moata/identity-sdk';
import Modal from '@/ui/Modal';
import { useAppDispatch } from '@/store/useAppDispatch';
import { setMessage } from '@/store/messages';
import { isConflictErrorGuard } from '@/services/api/helpers';
import { isActionNameReserved } from '@/features/admin/helpers';
import { AdminItemType } from '@/features/admin/types';
import { useCreateAction } from '@/features/admin/components/GroupConfigurationHeader/hooks/useCreateAction';
import { useUpdateAction } from '@/features/admin/components/GroupConfigurationHeader/hooks/useUpdateAction';
import actionFormSchema from './schema';

interface ProjectEditModalProps {
  itemType: AdminItemType;
  open: boolean;
  onClose: () => void;
  productId?: number;
  initialAction?: Action;
}
const ActionEditModal: FC<ProjectEditModalProps> = ({ open, onClose, initialAction, productId, itemType }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: zodResolver(actionFormSchema),
    defaultValues: {
      name: initialAction?.name,
      default: !!initialAction?.default,
      adminGroup: !!initialAction?.adminGroup,
      hidden: !!initialAction?.hidden,
      nonEditable: !!initialAction?.nonEditable,
      disabled: !!initialAction?.disabled,
      resourceMapping: !!initialAction?.resourceMapping,
    },
  });

  const { createAction, isLoading: isCreateLoading, error: createError } = useCreateAction(itemType);
  const { updateAction, isLoading: isUpdateLoading, error: updateError } = useUpdateAction(itemType);

  const isConflictError = isConflictErrorGuard(createError || updateError);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = handleSubmit(async (formData) => {
    if (!productId) return;

    if (isActionNameReserved(itemType, formData.name)) {
      setError('name', { message: 'This action name is restricted' });
      return;
    }

    const data = {
      name: formData.name ?? '',
      default: !!formData?.default,
      adminGroup: !!formData?.adminGroup,
      hidden: !!formData?.hidden,
      nonEditable: !!formData?.nonEditable,
      disabled: !!formData?.disabled,
      resourceMapping: !!formData?.resourceMapping,
    };

    try {
      if (initialAction) {
        await updateAction({ productId, actionId: initialAction.actionId, data });
      } else {
        await createAction({ productId, data });
      }
      dispatch(
        setMessage({ severity: 'success', content: `${formData.name} ${initialAction ? 'updated' : 'created'}` })
      );
      handleClose();
    } catch (e) {
      // FIXME remove after https://dev.azure.com/mmdigitalventures/Moata/_workitems/edit/71591 is done
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isActionExistError = e?.text?.includes('already exists in the current product');
      if (isActionExistError || (e instanceof Error && 'status' in e && e.status === 409)) {
        setError('name', { message: 'Action name already exists' });
      }
    }
  });

  return (
    <Modal
      closable
      message={
        (createError || updateError) && !isConflictError
          ? {
              content: 'There was an error creating the action',
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={handleClose}
      title={`${initialAction ? 'Edit' : 'New'} Action`}
    >
      <Modal.Content>
        <form data-testid="action-creation-modal" onSubmit={onSubmit}>
          <Stack>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  value={field.value ?? ''}
                  autoFocus
                  label="Action name"
                  placeholder="Name between 5 and 50 characters"
                  required
                  disabled={isCreateLoading || isUpdateLoading}
                  error={!!errors.name}
                  helperText={errors.name ? String(errors.name.message) : undefined}
                />
              )}
            />
            {itemType === AdminItemType.Project && (
              <>
                <Controller
                  control={control}
                  name="default"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Default"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="adminGroup"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Admin Group"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="hidden"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Hidden"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="nonEditable"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Non Editable"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="disabled"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Disabled"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="resourceMapping"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value ?? false}
                          disabled={isCreateLoading || isUpdateLoading}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Resource Mapping"
                    />
                  )}
                />
              </>
            )}
          </Stack>
          <Modal.Actions>
            <Button disabled={isCreateLoading || isUpdateLoading} variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty}
              loading={isCreateLoading || isUpdateLoading || !productId}
            >
              {initialAction ? 'Update' : 'Create'}
            </Button>
          </Modal.Actions>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default ActionEditModal;
