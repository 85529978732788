import { SerializedError } from '@reduxjs/toolkit';
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseQueryResult,
  useQueryClient,
  UseMutationResult,
  useMutation,
} from 'react-query';
import {
  GetUserIdByEmailParams,
  GetUserProjectsQueryParams,
  UserProjectDto,
  UserProductWithAccessDto,
  UserProfile,
} from '@mottmac-moata/identity-sdk';
import useAuth from '@/hooks/useAuth';
import { authorisedIdentityApiClient } from '@/services/api/identityApiClient';
import { ExtendedUserProfile, setUser } from '@/store/auth';
import { useAppDispatch } from '@/store/useAppDispatch';
import { useAppSelector } from '@/store/useAppSelector';
import { UserUpdate } from '@/services/api/UserAPI/types';
import { identifyChameleon } from '@/services/telemetry/chameleon';
import { USER_BASE_QUERY_KEY } from './constants';

const toExtendedUserProfile = (user: UserProfile): ExtendedUserProfile => ({
  ...user,
  isFirstTimeUser: !JSON.parse((user.additionalData.HasRegistered || 'False').toLocaleLowerCase()),
});

export const UserAPI = {
  queryKeys: {
    base: USER_BASE_QUERY_KEY,
    me: [USER_BASE_QUERY_KEY, 'me'] as QueryKey,
    userId: (email: string): QueryKey => [USER_BASE_QUERY_KEY, 'userId', email],
    productsWithAccess: [USER_BASE_QUERY_KEY, 'productsWithAccess'] as QueryKey,
    projects: [USER_BASE_QUERY_KEY, 'projects'] as QueryKey,
    project: (projectId: number) => [USER_BASE_QUERY_KEY, 'project', projectId] as QueryKey,
  },

  useFetchMe: (
    queryOptions?: Omit<
      UseQueryOptions<UserProfile, SerializedError, ExtendedUserProfile>,
      'initialData' | 'select' | 'refetchOnWindowFocus' | 'refetchInterval' | 'enabled'
    >
  ): UseQueryResult<ExtendedUserProfile, SerializedError> => {
    const { getAccessToken, isAuthenticated } = useAuth();
    const queryClient = useQueryClient();

    const dispatch = useAppDispatch();

    return useQuery(
      UserAPI.queryKeys.me,
      async ({ signal }) => {
        return authorisedIdentityApiClient(await getAccessToken()).User.me({ signal });
      },
      {
        ...queryOptions,
        onSuccess: (user) => {
          queryOptions?.onSuccess?.(user);

          // invalidate projects queries as this updates access
          queryClient.invalidateQueries(UserAPI.queryKeys.projects);

          // update stored value
          dispatch(setUser(user));

          // identify user in chameleon
          identifyChameleon({ user: { id: user.id, email: user.email, name: user.displayName } });
        },
        initialData: useAppSelector(({ auth }) => auth.user),
        select: (data: UserProfile) => toExtendedUserProfile(data),
        refetchOnWindowFocus: true,
        refetchInterval: 5 * 60 * 1000,
        enabled: isAuthenticated,
      }
    );
  },

  useUpdateMe: (): UseMutationResult<void, SerializedError, UserUpdate> => {
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation(
      async ({ firstName, lastName, mobilePhone }: UserUpdate) =>
        authorisedIdentityApiClient(await getAccessToken()).User.updateMe({
          givenName: firstName.trim(),
          surname: lastName.trim(),
          displayName: `${lastName.trim()}, ${firstName.trim()}`,
          additionalData: {
            HasRegistered: 'True',
          },
          ...(mobilePhone &&
            mobilePhone.trim().length > 5 && {
              mobilePhone: mobilePhone.trim(),
            }),
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(UserAPI.queryKeys.me);
        },
      }
    );
  },

  useGetUserId: (
    queryParams: GetUserIdByEmailParams,
    queryOptions?: UseQueryOptions<string | undefined, SerializedError>
  ): UseQueryResult<string | undefined, SerializedError> => {
    const { getAccessToken } = useAuth();

    return useQuery(
      UserAPI.queryKeys.userId(queryParams.email),
      async () => authorisedIdentityApiClient(await getAccessToken()).User.getUserId(queryParams),
      queryOptions
    );
  },

  useGetProductsWithAccess: (
    queryOptions?: UseQueryOptions<UserProductWithAccessDto[], SerializedError>
  ): UseQueryResult<UserProductWithAccessDto[], SerializedError> => {
    const { getAccessToken } = useAuth();

    return useQuery(
      UserAPI.queryKeys.productsWithAccess,
      async () => authorisedIdentityApiClient(await getAccessToken()).User.getProductsWithAccess(),
      queryOptions
    );
  },

  useFetchProjects: (
    queryParams?: GetUserProjectsQueryParams,
    queryOptions?: UseQueryOptions<UserProjectDto[], SerializedError>
  ): UseQueryResult<UserProjectDto[], SerializedError> => {
    const { getAccessToken } = useAuth();

    return useQuery(
      UserAPI.queryKeys.projects,
      async ({ signal }) =>
        authorisedIdentityApiClient(await getAccessToken()).User.getProjects(queryParams, { signal }),
      queryOptions
    );
  },

  useGetProjectById: (
    projectId: number,
    queryOptions?: UseQueryOptions<UserProjectDto, SerializedError>
  ): UseQueryResult<UserProjectDto, SerializedError> => {
    const { getAccessToken } = useAuth();

    return useQuery(
      UserAPI.queryKeys.project(projectId),
      async ({ signal }) => {
        const projects = await authorisedIdentityApiClient(await getAccessToken()).User.getProjectById(
          { projectId },
          { signal }
        );
        return projects[0];
      },
      queryOptions
    );
  },
};
