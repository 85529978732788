import { SerializedError } from '@reduxjs/toolkit';
import { ProjectGroupAPI } from '@/services/api/ProjectGroupAPI';
import { ProductGroupAPI } from '@/services/api/ProductGroupAPI';
import { AdminItemType } from '@/features/admin/types';

export const useGroupCreate = (
  type: AdminItemType,
  itemId: number
): { create: (name: string) => Promise<void>; isLoading: boolean; error?: SerializedError } => {
  const {
    mutateAsync: createProjectGroup,
    isLoading: isLoadingProjectGroupCreate,
    error: errorProjectGroupCreate,
  } = ProjectGroupAPI.useCreate();
  const {
    mutateAsync: createProductGroup,
    isLoading: isLoadingProductGroupCreate,
    error: errorProductGroupCreate,
  } = ProductGroupAPI.useCreate();

  const create = async (name: string): Promise<void> => {
    if (type === AdminItemType.Project) {
      await createProjectGroup({ projectId: itemId, payload: { name } });
    } else {
      await createProductGroup({ productId: itemId, payload: { name } });
    }
  };

  return {
    create,
    isLoading: isLoadingProductGroupCreate || isLoadingProjectGroupCreate,
    error: errorProductGroupCreate || errorProjectGroupCreate || undefined,
  };
};
