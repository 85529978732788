import { useEffect } from 'react';
import { getB2cTokens } from '@/store/auth/selectors';
import { useAppSelector } from '@/store/useAppSelector';
import { REFRESH_TOKEN_THRESHOLD_IN_MS } from '@/utils/constants';
import { getJwtExpiresAt } from '@/utils/jwtHelpers';
import useAuth from '@/hooks/useAuth';

const getRefreshDelay = (token: string): number => {
  const expiry = getJwtExpiresAt(token);
  const delay = expiry - REFRESH_TOKEN_THRESHOLD_IN_MS - Date.now();
  return delay > 0 ? delay : 0;
};

export const useAutoRefreshTokens = (): void => {
  const { access_token: accessToken } = useAppSelector(getB2cTokens) ?? {};
  const { refreshTokens } = useAuth();

  useEffect(() => {
    if (!accessToken) return undefined;

    const refreshDelay = getRefreshDelay(accessToken);

    const abortController = new AbortController();

    const timer = setTimeout(() => {
      refreshTokens(abortController.signal).catch(() => {
        // suspend error
        // slightly refresh
      });
    }, refreshDelay);

    return () => {
      abortController.abort();
      clearTimeout(timer);
    };
  }, [accessToken, refreshTokens]);
};
