import { MutateOptions } from 'react-query';
import { UpdateProductActionDto } from '@mottmac-moata/identity-sdk';
import { AdminItemType } from '@/features/admin/types';
import { ProductActionAPI } from '@/services/api/ProductActionAPI';
import { ProjectActionAPI } from '@/services/api/ProjectActionAPI';

export const useUpdateAction = (
  itemType: AdminItemType
): {
  updateAction: (
    variables: {
      productId: number;
      actionId: string;
      data: UpdateProductActionDto;
    },
    options?: MutateOptions<
      void,
      unknown,
      {
        productId: number;
        actionId: string;
        data: UpdateProductActionDto;
      },
      unknown
    >
  ) => Promise<void>;
  isLoading: false | true;
  isError: false | true;
  error: unknown;
} => {
  const {
    mutateAsync: updateProjectAction,
    isLoading: isProjectActionLoading,
    isError: isProjectActionError,
    error: projectError,
  } = ProjectActionAPI.useUpdate();
  const {
    mutateAsync: updateProductAction,
    isLoading: isProductActionLoading,
    isError: isProductActionError,
    error: productError,
  } = ProductActionAPI.useUpdate();

  return {
    updateAction: itemType === AdminItemType.Project ? updateProjectAction : updateProductAction,
    isLoading: isProductActionLoading || isProjectActionLoading,
    isError: isProductActionError || isProjectActionError,
    error: projectError || productError,
  };
};
