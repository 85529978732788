import { Action, CreateProductActionDto, UpdateProductActionDto } from '@mottmac-moata/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from '@/hooks/useAuth';
import { authorisedIdentityApiClient } from '@/services/api/identityApiClient';
import { QUERY_KEY_VARIANTS } from '@/services/api/constants';
import { PROJECT_ACTION_BASE_QUERY_KEY } from './constants';

export const ProjectActionAPI = {
  queryKeys: {
    base: PROJECT_ACTION_BASE_QUERY_KEY,
    byProductId: (productId: number) => [ProjectActionAPI.queryKeys.base, productId] as QueryKey,
    list: (productId: number) =>
      [...ProjectActionAPI.queryKeys.byProductId(productId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
  },

  useGetAll: (productId: number, options?: UseQueryOptions<Action[], SerializedError>) => {
    const { getAccessToken } = useAuth();

    return useQuery(
      ProjectActionAPI.queryKeys.list(productId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getAccessToken()).ProjectAction.getAll(productId, { signal }),
      options
    );
  },
  useCreate: () => {
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ productId, data }: { productId: number; data: CreateProductActionDto }) =>
        authorisedIdentityApiClient(await getAccessToken()).ProjectAction.create({ productId, data }),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProjectActionAPI.queryKeys.list(productId));
      },
    });
  },
  useUpdate: () => {
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({
        productId,
        actionId,
        data,
      }: {
        productId: number;
        actionId: string;
        data: UpdateProductActionDto;
      }) => authorisedIdentityApiClient(await getAccessToken()).ProjectAction.update({ productId, actionId, data }),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProjectActionAPI.queryKeys.list(productId));
      },
    });
  },
  useDeleteOne: () => {
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ productId, actionId }: { productId: number; actionId: string }) =>
        authorisedIdentityApiClient(await getAccessToken()).ProjectAction.deleteOne(productId, actionId),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProjectActionAPI.queryKeys.list(productId));
      },
    });
  },
};
